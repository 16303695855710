import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 50%;
  line-height: 20px;
  font-size: 10px;
  background-color: ${theme.colors.green};
  overflow: hidden;
  color: white;
  font-weight: 700;
  ${theme.below.lg} {
    height: 15px;
    width: 15px;
    line-height: 15px;
  }

  span {
    height: 0.9rem;
    width: 0.9rem;
    border: 0;
    border-radius: 50%;
    font-size: 0.5rem;
    line-height: 0.9rem;
    color: white;
    background-color: ${theme.colors.green};
    overflow: hidden;
  }
`;

const Badge = ({ text }) => {
  return (
    <Wrapper className="badge">
      <div>{text}</div>
    </Wrapper>
  );
};

export default Badge;
