import CategoryLink from '@jetshop/ui/CategoryLink';
import { Link } from 'react-router-dom';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from 'linaria/react';
import SubMenuWrapper from './SubMenuWrapper';
import { theme } from '../../../Theme';

const Wrapper = styled('div')`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const MainMenuItem = styled('li')`
  a {
    padding: 1rem;
    display: inline-block;
    color: #333333;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-family: ${theme.fonts.primary};
    &.active {
      color: #000;
      font-weight: bold;
    }
  }
`;

const CategoryMenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul>
            {queryData?.length > 0
              ? queryData.map(cat => (
                  <CategoryMenuItem key={cat.id} cat={cat} props={props} />
                ))
              : null}
          </ul>

          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props?.activeCategories[0]}
            closeNav={props?.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

const CategoryMenuItem = ({ cat, props }) => {
  return (
    <MainMenuItem
      // When mousing over a menu item, set it as the active nav
      onMouseEnter={() => props.setActiveCategory(cat)}
    >
      <CategoryLink
        // When following a category link, close the menu
        onClick={props.clearActiveCategories}
        category={cat}
      >
        {cat.mainHeader}
      </CategoryLink>
    </MainMenuItem>
  );
};

const MasterMenu = () => {
  const links = [
    {
      text: 'Fotball',
      link: '/fotball'
    },
    {
      text: 'Løp',
      link: '/lop'
    },
    {
      text: 'Hockey',
      link: '/hockey'
    },
    {
      text: 'Klubbservice',
      link: '/klubbservice'
    },
    {
      text: 'Andre idretter',
      link: '/andre-idretter'
    }
  ];

  return (
    <UIMenuContainer>
      {props => (
        <Wrapper>
          <ul>
            {links.map(top => (
              <MainMenuItem>
                <Link to={top.link}>{top.text}</Link>
              </MainMenuItem>
            ))}
          </ul>
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

export default CategoryMenuContainer;
export { MasterMenu };
