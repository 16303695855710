import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import React from 'react';
import { validateNumber } from '../../utils/HelpFunctions';

const SpacerWrapper = styled('div')`
  width: 100%;
  height: ${props => props.spacerheight}px;
`;

export const Spacer = ({ space, mobilePercentage }) => {
  const selectedSpace = validateNumber(space?.value?.replace('px', ''));
  const percentage = validateNumber(mobilePercentage?.value);

  const decimalValue = percentage ? percentage / 100 : 0.7;
  return (
    <Above breakpoint="md">
      {matches =>
        matches ? (
          <SpacerWrapper spacerheight={selectedSpace} />
        ) : (
          <SpacerWrapper spacerheight={selectedSpace * decimalValue} />
        )
      }
    </Above>
  );
};
