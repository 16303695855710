import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';

const CurrenSlideWrapper = styled('div')`
  position: absolute;
  padding: 5px 10px;
  background: ${props => props.backgroundcolor};
  color: ${props => props.foregroundcolor};
  z-index: 2;
  border-radius: 11px;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;

  &.TOP {
    top: 1rem;
    bottom: auto;
  }
  &.MIDDLE {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
  &.BOTTOM {
    top: auto;
    bottom: 1rem;
  }
  &.LEFT {
    left: 1rem;
    right: auto;
  }
  &.CENTER {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  &.RIGHT {
    left: auto;
    right: 1rem;
  }
`;

export const ImageGallerySlidePresenter = ({
  current,
  total,
  backgroundColor,
  foregroundColor,
  verticalPosition,
  horizontalPosition
}) => {
  return (
    <CurrenSlideWrapper
      backgroundcolor={backgroundColor?.value}
      foregroundcolor={foregroundColor?.value}
      className={cx(verticalPosition?.value, horizontalPosition?.value)}
    >
      {current + ' / ' + total}
    </CurrenSlideWrapper>
  );
};
