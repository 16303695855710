import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from '../../../Theme';
import { ReactComponent as AngleRight } from '../../../../svg/AngleRight.svg';

const Wrapper = styled('div')`
  flex: 1 1 auto;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  margin-bottom: 20px;
  padding-left: 15px;
  border-left: 1px solid #ddd;

  text-align: left;
  * {
    text-align: left;
  }

  h2 {
    margin: 0;
    padding-bottom: 0;
    a {
      padding: 0;
      font-size: 15px;
      font-weight: bold;
      text-transform: none;
      padding: 0 0 5px 0;
      svg {
        width: 8px;
        margin-left: 10px;
        margin-top: -2px;
        opacity: 0.6;
      }
    }
  }

  ul {
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
    display: block !important;
  }

  li {
    color: black;
    flex: auto;
    padding: 0;
    margin: 0;
    a {
      color: black;
      display: block;
      font-size: 15px;
      padding: 5px 0;
      :hover,
      &.active {
        color: ${theme.colors.black};
      }
    }
  }
  a {
    text-decoration: none;
    color: ${theme.colors.black};
    display: block;
    :hover {
      text-decoration: underline;
    }
  }
`;

const Heading = styled('h2')`
  a {
  }
`;

const SubMenuSection = ({
  heading,
  categories,
  onMouseOver,
  onSelect = () => {}
}) => {
  return (
    <Wrapper onMouseOver={onMouseOver}>
      <Heading>
        <CategoryLink onClick={onSelect} category={heading}>
          {heading?.name}
          <AngleRight />
        </CategoryLink>
      </Heading>
      <ul>
        {categories.map(cat => (
          <li key={cat.id}>
            <CategoryLink onClick={onSelect} category={cat}>
              {cat?.images?.length > 0 && cat?.parent?.id !== 559 && (
                <img
                  src={cat.images[0].url}
                  alt={cat.name}
                  style={{ marginRight: '8px', width: '25px', height: 'auto' }}
                />
              )}
              {cat.name}
            </CategoryLink>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default SubMenuSection;
