import React from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { useQuery } from 'react-apollo';
import CategoryContentQuery from './CategoryContentQuery.gql';
import ContentPageContentQuery from '../ContentPage/ContentPageContentQuery.gql';
import { contentEditorBaseComponents } from './ContentEditorComponents';

export const DynamicContentRenderer = ({ categoryId, rendererComponents }) => {
  const { data, error } = useQuery(CategoryContentQuery, {
    variables: {
      id: categoryId
    },
    skip: !categoryId
  });

  if (!data || error) {
    return null;
  }
  const components = rendererComponents ?? contentEditorBaseComponents;
  const items = data?.category?.data?.items;

  return (
    <>{items && <ContentRenderer items={items} components={components} />}</>
  );
};

export const DynamicPageContentRenderer = ({ pageId, rendererComponents }) => {
  const { data, error } = useQuery(ContentPageContentQuery, {
    variables: {
      id: pageId
    }
  });

  if (!data || error) {
    return null;
  }
  const components = rendererComponents ?? contentEditorBaseComponents;
  const items = data?.page?.data?.items;

  return (
    <>{items && <ContentRenderer items={items} components={components} />}</>
  );
};
