import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/Image';

import { theme } from '../../Theme';

const Wrapper = styled('div')`
  margin-bottom: 60px;
`;
const Inner = styled('div')`
  display: flex;
  flex-wrap: wrap;
  ${theme.below.lg} {
    display: block;
  }
`;
const Row = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;
const Column = styled('div')`
  flex: 1;
`;
const Item = styled(Link)`
  display: block;
  position: relative;
  outline: 3px solid white;
  img {
    transition: all, 0.5s ease !important;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;
const Content = styled('div')`
  position: absolute;
  padding: 40px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: ${props => (props.color ? props.color : 'white')};
  ${theme.below.lg} {
    padding: 20px;
  }
  h3 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    line-height: 1;
    font-weight: 400;
    ${theme.below.lg} {
      font-size: 11px;
    }
  }
  h2 {
    font-size: 35px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    line-height: 1;
    ${theme.below.lg} {
      font-size: 16px;
    }
  }

  &.large {
    top: 38px;
    transform: none;
    bottom: auto;
    max-width: 450px;
    ${theme.below.lg} {
      top: 20px;
    }
    h3 {
      font-size: 18px;
      ${theme.below.lg} {
        font-size: 14px;
      }
    }
    h2 {
      font-size: 50px;
      ${theme.below.lg} {
        font-size: 35px;
      }
    }
  }
`;

const FakeButton = styled('span')`
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 30px;
  background: ${props => (props.background ? props.background : 'black')};
  font-size: 11px;
  font-weight: 700;
  margin-top: 20px;
  transition: all, 0.2s ease;
  color: ${props => (props.color ? props.color : 'white')};

  &:hover {
    background: ${props =>
      props.backgroundhover ? props.backgroundhover : 'black'};
    color: ${props => (props.colorhover ? props.colorhover : 'white')};
  }

  ${theme.below.lg} {
    margin-top: 5px;
  }
`;

const TopBlock = ({ block1, block2, block3, block4 }) => {
  return (
    <Wrapper>
      <Inner>
        <Column>
          <Item
            to={
              block1?.external?.length > 0
                ? {
                    pathname: block1.link
                  }
                : block1.link
            }
            target={block1?.external?.length > 0 ? '_blank' : '_self'}
          >
            <Image src={block1.image} aspect={'3:2'} cover={true} />
            <Content className="large">
              <h3>{block1.smallTitle}</h3>
              <h2>{block1.title}</h2>
              {block1.buttons.map((button, index) => (
                <FakeButton
                  key={index}
                  background={button.background ? button.background : 'white'}
                  color={button.color ? button.color : 'black'}
                  backgroundhover={
                    button.backgroundhover ? button.backgroundhover : 'black'
                  }
                  colorhover={button.colorhover ? button.colorhover : 'white'}
                >
                  {button.text}
                </FakeButton>
              ))}
            </Content>
          </Item>
        </Column>

        <Column>
          <Row>
            <Column>
              <Item
                to={
                  block2?.external?.length > 0
                    ? {
                        pathname: block2.link
                      }
                    : block2.link
                }
                target={block2?.external?.length > 0 ? '_blank' : '_self'}
              >
                <Image src={block2.image} aspect={'3:1'} cover={true} />
                <Content>
                  <h3>{block2.smallTitle}</h3>
                  <h2>{block2.title}</h2>
                  {block2.buttons.map((button, index) => (
                    <FakeButton
                      key={index}
                      background={
                        button.background ? button.background : 'white'
                      }
                      color={button.color ? button.color : 'black'}
                      backgroundhover={
                        button.backgroundhover
                          ? button.backgroundhover
                          : 'black'
                      }
                      colorhover={
                        button.colorhover ? button.colorhover : 'white'
                      }
                    >
                      {button.text}
                    </FakeButton>
                  ))}
                </Content>
              </Item>
            </Column>
          </Row>
          <Row>
            <Column>
              <Item
                to={
                  block3?.external?.length > 0
                    ? {
                        pathname: block3.link
                      }
                    : block3.link
                }
                target={block3?.external?.length > 0 ? '_blank' : '_self'}
              >
                <Image src={block3.image} aspect={'3:2'} cover={true} />
                <Content>
                  <h3>{block3.smallTitle}</h3>
                  <h2>{block3.title}</h2>
                  {block3.buttons.map((button, index) => (
                    <FakeButton
                      key={index}
                      background={
                        button.background ? button.background : 'white'
                      }
                      color={button.color ? button.color : 'black'}
                      backgroundhover={
                        button.backgroundhover
                          ? button.backgroundhover
                          : 'black'
                      }
                      colorhover={
                        button.colorhover ? button.colorhover : 'white'
                      }
                    >
                      {button.text}
                    </FakeButton>
                  ))}
                </Content>
              </Item>
            </Column>
            <Column>
              <Item
                to={
                  block4?.external?.length > 0
                    ? {
                        pathname: block4.link
                      }
                    : block4.link
                }
                target={block4?.external?.length > 0 ? '_blank' : '_self'}
              >
                <Image src={block4.image} aspect={'3:2'} cover={true} />
                <Content>
                  <h3>{block4.smallTitle}</h3>
                  <h2>{block4.title}</h2>
                  {block4.buttons.map((button, index) => (
                    <FakeButton
                      key={index}
                      background={
                        button.background ? button.background : 'white'
                      }
                      color={button.color ? button.color : 'black'}
                      backgroundhover={
                        button.backgroundhover
                          ? button.backgroundhover
                          : 'black'
                      }
                      colorhover={
                        button.colorhover ? button.colorhover : 'white'
                      }
                    >
                      {button.text}
                    </FakeButton>
                  ))}
                </Content>
              </Item>
            </Column>
          </Row>
        </Column>
      </Inner>
    </Wrapper>
  );
};

export default TopBlock;
