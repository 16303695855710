import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import { Link } from 'react-router-dom';
import { FakeButton, LinkButton } from '../StartPage/Content/Row';
import { createAnchorProps } from '../../utils/HelpFunctions';
import { useInView } from 'react-intersection-observer';

const BoxRowWrapper = styled('div')`
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  margin: 0 auto;
  flex: 1 1 auto;
  max-width: 80rem;
  padding: 0;
  width: 100%;
  flex-direction: row;
  position: relative;

  ${theme.above.sm} {
    padding: 0 2rem;
  }

  ${theme.below.lg} {
    flex-direction: column;
  }

  &.full-width {
    max-width: 100%;
    ${theme.above.sm} {
      padding: 0;
    }
  }

  &.reverse {
    flex-direction: row-reverse !important;
    ${theme.below.lg} {
      flex-direction: column-reverse !important;
    }
  }

  &.gap {
    column-gap: 3px;
    ${theme.below.lg} {
      row-gap: 3px;
    }
  }
`;

export const BoxRow = ({ reverse, fullWidth, gap, anchorTag, children }) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);
  const anchorProps = createAnchorProps(anchorTag?.value);

  return (
    <BoxRowWrapper
      ref={ref}
      {...anchorProps}
      className={cx(
        !!reverse?.value && 'reverse',
        !!fullWidth?.value && 'full-width',
        'gap'
      )}
    >
      {children}
    </BoxRowWrapper>
  );
};

export const boxStyling = css`
  img {
    transition: all, 0.5s ease !important;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  h2 {
    font-weight: 700;
    font-size: 35px;
    margin: 0 0 5px 0;
    line-height: 1;
  }
  h3 {
    font-size: 16px;
    margin: 0 0 5px 0;
    line-height: 1;
    font-weight: 400;
  }
`;

const LinkedImageBoxLinkWrapper = styled(Link)`
  flex: ${props => props.flexwidth};
  text-decoration: none;

  [data-flight-image-children] {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
  }

  &.TOP {
    [data-flight-image-children] {
      justify-content: flex-start;
    }
  }

  &.MIDDLE {
    [data-flight-image-children] {
      justify-content: center;
    }
  }

  &.BOTTOM {
    [data-flight-image-children] {
      justify-content: flex-end;
    }
  }

  &.LEFT {
    [data-flight-image-children] {
      align-items: flex-start;
    }
  }

  &.CENTER {
    [data-flight-image-children] {
      align-items: center;
    }
  }

  &.RIGHT {
    [data-flight-image-children] {
      align-items: flex-end;
    }
  }
`;

const ImageBoxLinkWrapper = styled('div')`
  flex: ${props => props.flexwidth};
  [data-flight-image-children] {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 2rem;
    margin: auto;
  }

  &.TOP {
    [data-flight-image-children] {
      justify-content: flex-start;
    }
  }

  &.MIDDLE {
    [data-flight-image-children] {
      justify-content: center;
    }
  }

  &.BOTTOM {
    [data-flight-image-children] {
      justify-content: flex-end;
    }
  }

  &.LEFT {
    [data-flight-image-children] {
      align-items: flex-start;
    }
  }

  &.CENTER {
    [data-flight-image-children] {
      align-items: center;
    }
  }

  &.RIGHT {
    [data-flight-image-children] {
      align-items: flex-end;
    }
  }
`;

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  color: ${props => props.textcolor};
  /* margin: 2rem; */
  max-width: 80%;

  ${theme.below.lg} {
    max-width: 100%;
  }

  &.LEFT {
    text-align: left;
    .buttons-row-wrapper {
      justify-content: flex-start;
    }
  }

  &.CENTER {
    text-align: center;
    .buttons-row-wrapper {
      justify-content: center;
    }
  }

  &.RIGHT {
    text-align: right;
    .buttons-row-wrapper {
      justify-content: flex-end;
    }
  }
`;

const ButtonsRowWrapper = styled('div')`
  display: flex;

  a + a,
  span + span {
    margin-left: 10px;
  }
`;

const BoxBadgeWrapper = styled('div')`
  position: absolute;
  padding: 0.5rem 1rem;
  background: ${props => props.bgcolor};
  color: ${props => props.textcolor};
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;

  &.TOP {
    top: 0;
    bottom: auto;
  }

  &.MIDDLE {
    top: 50%;
    bottom: auto;
  }

  &.BOTTOM {
    top: auto;
    bottom: 0;
  }

  &.LEFT {
    right: auto;
    left: 0;
    transform-origin: 0 0;
    transform: rotate(-90deg) translateX(-50%);
  }

  &.CENTER {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  &.RIGHT {
    right: 0;
    left: auto;
    transform-origin: 100% 0;
    transform: rotate(90deg) translateX(50%);
  }
`;

const BoxBadge = ({
  backgroundColor,
  textColor,
  text,
  horizontalAlign,
  verticalAlign
}) => {
  return (
    <BoxBadgeWrapper
      bgcolor={backgroundColor?.value ?? 'black'}
      textcolor={textColor?.value ?? 'white'}
      className={cx(
        horizontalAlign?.value ?? 'LEFT',
        verticalAlign?.value ?? 'BOTTOM'
      )}
    >
      {text?.value}
    </BoxBadgeWrapper>
  );
};

const InnerImageBoxLink = ({
  width,
  desktopImage,
  desktopImageRatio,
  mobileImage,
  mobileImageRatio,
  boxLink,
  verticalAlign,
  horizontalAlign,
  title,
  text,
  titleColor,
  button1Text,
  button1Url,
  button1Theme,
  button2Text,
  button2Url,
  button2Theme,
  badgeBackgroundColor,
  badgeTextColor,
  badgeText,
  badgeHorizontalAlign,
  badgeVerticalAlign
}) => {
  const button1Props = {
    background: button1Theme?.value === 'LIGHT' ? 'white' : 'black',
    color: button1Theme?.value === 'LIGHT' ? 'black' : 'white',
    backgroundhover: button1Theme?.value === 'LIGHT' ? 'black' : 'white',
    colorhover: button1Theme?.value === 'LIGHT' ? 'white' : 'black',
    bordercolor: button1Theme?.value === 'LIGHT' ? 'white' : 'white'
  };
  const button2Props = {
    background: button2Theme?.value === 'LIGHT' ? 'white' : 'black',
    color: button2Theme?.value === 'LIGHT' ? 'black' : 'white',
    backgroundhover: button2Theme?.value === 'LIGHT' ? 'black' : 'white',
    colorhover: button2Theme?.value === 'LIGHT' ? 'white' : 'black',
    bordercolor: button2Theme?.value === 'LIGHT' ? 'white' : 'white'
  };
  const imageSizes = [1, 1, 1, 300, 600];
  return (
    <div style={{ position: 'relative' }}>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <Image
              src={desktopImage?.value}
              aspect={desktopImageRatio?.value}
              critical
              cover
            >
              {badgeText?.value && (
                <BoxBadge
                  backgroundColor={badgeBackgroundColor}
                  textColor={badgeTextColor}
                  text={badgeText}
                  horizontalAlign={badgeHorizontalAlign}
                  verticalAlign={badgeVerticalAlign}
                />
              )}
              <ContentWrapper
                className={cx(horizontalAlign?.value ?? 'CENTER')}
                textcolor={titleColor?.value ?? '#ffffff'}
              >
                {title?.value && <h2>{title?.value}</h2>}
                {text?.value && <h3>{text?.value}</h3>}
                <ButtonsRowWrapper className={'buttons-row-wrapper'}>
                  {boxLink?.value?.length > 0 ? (
                    <>
                      {button1Text?.value && (
                        <FakeButton {...button1Props}>
                          {button1Text?.value}
                        </FakeButton>
                      )}
                      {button2Text?.value && (
                        <FakeButton {...button2Props}>
                          {button2Text?.value}
                        </FakeButton>
                      )}
                    </>
                  ) : (
                    <>
                      {button1Text?.value && button1Url?.value && (
                        <LinkButton {...button1Props} to={button1Url?.value}>
                          {button1Text?.value}
                        </LinkButton>
                      )}
                      {button2Text?.value && button2Url?.value && (
                        <LinkButton {...button2Props} to={button2Url?.value}>
                          {button2Text?.value}
                        </LinkButton>
                      )}
                    </>
                  )}
                </ButtonsRowWrapper>
              </ContentWrapper>
            </Image>
          ) : (
            <Image
              src={mobileImage?.value}
              aspect={mobileImageRatio?.value}
              sizes={imageSizes}
              critical
              cover
            >
              {badgeText?.value && (
                <BoxBadge
                  backgroundColor={badgeBackgroundColor}
                  textColor={badgeTextColor}
                  text={badgeText}
                  horizontalAlign={badgeHorizontalAlign}
                  verticalAlign={badgeVerticalAlign}
                />
              )}
              <ContentWrapper
                className={cx(horizontalAlign?.value ?? 'CENTER')}
                textcolor={titleColor?.value ?? '#ffffff'}
              >
                {title?.value && <h2>{title?.value}</h2>}
                {text?.value && <h3>{text?.value}</h3>}
                <ButtonsRowWrapper className={'buttons-row-wrapper'}>
                  {boxLink?.value?.length > 0 ? (
                    <>
                      {button1Text?.value && (
                        <FakeButton {...button1Props}>
                          {button1Text?.value}
                        </FakeButton>
                      )}
                      {button2Text?.value && (
                        <FakeButton {...button2Props}>
                          {button2Text?.value}
                        </FakeButton>
                      )}
                    </>
                  ) : (
                    <>
                      {button1Text?.value && button1Url?.value && (
                        <LinkButton {...button1Props} to={button1Url?.value}>
                          {button1Text?.value}
                        </LinkButton>
                      )}
                      {button2Text?.value && button2Url?.value && (
                        <LinkButton {...button2Props} to={button2Url?.value}>
                          {button2Text?.value}
                        </LinkButton>
                      )}
                    </>
                  )}
                </ButtonsRowWrapper>
              </ContentWrapper>
            </Image>
          )
        }
      </Above>
    </div>
  );
};

export const ImageBoxLink = props => {
  return (
    <>
      {props?.boxLink?.value && props?.boxLink?.value?.length > 0 ? (
        <LinkedImageBoxLinkWrapper
          to={props?.boxLink?.value}
          className={cx(
            boxStyling,
            props?.verticalAlign?.value,
            props?.horizontalAlign?.value
          )}
          flexwidth={props?.width?.value}
        >
          <InnerImageBoxLink {...props} />
        </LinkedImageBoxLinkWrapper>
      ) : (
        <ImageBoxLinkWrapper
          className={cx(
            boxStyling,
            props?.verticalAlign?.value,
            props?.horizontalAlign?.value
          )}
          flexwidth={props?.width?.value}
        >
          <InnerImageBoxLink {...props} />
        </ImageBoxLinkWrapper>
      )}
    </>
  );
};
