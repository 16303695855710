/* eslint-disable array-callback-return */
import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  background: white;
  margin-bottom: 0.5rem;
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  > :first-child {
    flex: 1 1 20%;
  }
  > :last-child {
    flex: 1 1 75%;
    padding: 0 10px 10px 10px;
  }

  .item-image {
    > div {
      border: 1px solid #e0e0e0;
    }
  }

  .attribute-wrapper {
    grid-area: bottomleft;
    .attribute {
      display: block;
      font-size: 10px;
    }
  }

  .comment {
    font-size: 10px;
    margin-right: -20px;
  }
`;

const ProductName = styled('div')`
  font-size: 0.875rem;
  font-weight: normal;
  a {
    color: #828282;
  }
  h2 {
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 10px;
  }
`;

const ProductDetail = styled('section')`
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
`;

const ItemDetails = styled('div')`
  grid-area: topleft;
`;

const LinePrice = styled(Price)`
  margin-top: 0;
  grid-area: topright;
  font-weight: 600;
  text-align: right;
  font-size: 11px;
`;

const AdjustQty = styled('div')`
  display: flex;
  grid-area: bottomright;
  justify-content: flex-end;
  /* Plus/minus buttons */
  button {
    background: none;
    border: 0;
    color: #333333;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    margin: 0 2px;
    display: inline-block;
    font-size: 12px;
    border: 1px solid #333333;
    height: 20px;
    width: 25px;
    text-align: center;
  }
`;

const RemoveItem = styled('div')`
  grid-area: topright;
  text-align: right;
  position: absolute;
  left: 12.5px;
  top: 12.5px;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: none;
    background: black;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    height: 8px;
    width: 8px;
  }
`;

const PrintExtrasWrapper = styled('ul')`
  grid-column: 1 / 5;
  margin-top: 8px;
  li {
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    .price-display {
      font-weight: 700;
    }
  }
`;

const PrintExtras = ({ items, product }) => {
  if (!items) return null;
  const cleanItems = items.filter(
    item => item.articleNumber !== product.articleNumber
  );
  const cleanName =
    product.customerComments.find(itm => itm.name === 'cleanName')?.value ??
    null;
  return (
    <PrintExtrasWrapper>
      <li>{cleanName}</li>
      {cleanItems.map(item => {
        return (
          <li>
            {item.product.name}
            <LinePrice
              data-testid="item-price"
              price={item.total}
              previousPrice={item.previousTotal}
            />
          </li>
        );
      })}
    </PrintExtrasWrapper>
  );
};

const CartItem = ({ item, className = '', allItems }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);
  const mainId =
    item.customerComments.find(cmnt => cmnt.name === 'configId')?.value ?? null;
  const matchingItems = allItems.filter(subItem => {
    const subId =
      subItem.customerComments.find(cmnt => cmnt.name === 'configId')?.value ??
      null;
    if (mainId && mainId === subId) {
      return subItem;
    }
  });
  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  const variantNamesValues = variantValues.map(
    (value, index) => `${item.variantOptionNames[index]}: ${value}`
  );

  return (
    <Wrapper className={className}>
      <div className="item-image">
        {item.product.images.length > 0 && (
          <Image
            aspect="1:1"
            sizes="5rem"
            src={
              hasVariantImage ? variantImage.url : item.product.images[0].url
            }
            alt={
              hasVariantImage ? variantImage.alt : item.product.images[0].alt
            }
            quality={80}
          />
        )}
      </div>
      <ProductDetail>
        <ItemDetails>
          <FlyoutTrigger id="cart-flyout">
            {flyout => (
              <ProductName onClick={flyout.hideTarget}>
                <Link to={item.product.primaryRoute.path}>
                  <h2 data-testid="item-name">
                    <strong>{item.product.subName}</strong>{' '}
                    {item.product.name.replace(item.product.subName, '')}
                    {/* {item.product.name} */}
                  </h2>
                </Link>
              </ProductName>
            )}
          </FlyoutTrigger>
        </ItemDetails>

        <div className="attribute-wrapper">
          {isVariant && (
            <ul
              style={{
                marginTop: '0'
              }}
            >
              {variantNamesValues.map((variantOptionNameValue, index) => (
                <li className="attribute" key={index}>
                  {variantOptionNameValue}
                </li>
              ))}
            </ul>
          )}
          {item.configurations.length > 0 && (
            <ul
              style={{
                marginTop: '0'
              }}
            >
              {item.configurations.map(({ option: { name } }, index) => (
                <li className="attribute" key={index}>
                  {name}
                </li>
              ))}
            </ul>
          )}
        </div>

        <RemoveItem>
          <button
            onClick={() => {
              if (matchingItems.length > 1) {
                matchingItems.forEach(subItem => {
                  removeFromCart({
                    itemId: subItem.id,
                    product: subItem.product
                  });
                });
              } else {
                removeFromCart({ itemId: item.id, product: item.product });
              }
            }}
            data-testid="remove-from-cart"
          >
            <Cross />
          </button>
        </RemoveItem>

        <AdjustQty>
          <button
            data-testid="decrement-quantity"
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            -
          </button>
          <span data-testid="item-quantity">{item.quantity}</span>
          <button
            data-testid="increment-quantity"
            onClick={() => incrementQuantity({ itemId: item.id })}
          >
            +
          </button>
        </AdjustQty>

        <LinePrice
          data-testid="item-price"
          price={item.total}
          previousPrice={item.previousTotal}
        />
        <PrintExtras items={matchingItems} product={item} />
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
