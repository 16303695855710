import { styled } from 'linaria/react';
import React from 'react';
import { createAnchorProps } from '../../utils/HelpFunctions';
import { theme } from '../Theme';

const HtmlRowWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: ${props => props.flexdirection};
  background: ${props => props.bgcolor};
  padding: ${props => props.pdding};
  column-gap: ${props => props.gap};
  row-gap: ${props => props.gap};
  max-width: 80rem;

  &.full-width {
    max-width: 100%;
    max-width: none;
    padding-left: 0;
    padding-right: 0;

    > div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  ${theme.below.lg} {
    flex-direction: column;
  }
`;

export const HtmlRow = ({
  direction,
  backgroundColor,
  padding,
  boxGap,
  fullWidth,
  anchorTag,
  children
}) => {
  const anchorProps = createAnchorProps(anchorTag?.value);
  let flexDirection = 'row';
  switch (direction?.value) {
    case 'HORIZONTAL':
      flexDirection = 'row';
      break;
    case 'VERTICAL':
      flexDirection = 'column';
      break;

    default:
      flexDirection = 'row';
      break;
  }

  return (
    <HtmlRowWrapper
      {...anchorProps}
      className={!!fullWidth?.value && 'full-width'}
      bgcolor={backgroundColor?.value ?? '#ffffff'}
      pdding={padding?.value}
      gap={boxGap?.value}
      flexdirection={flexDirection}
    >
      {children}
    </HtmlRowWrapper>
  );
};
