import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import 'slick-carousel/slick/slick.css';
import {
  createAnchorProps,
  filterChildrenByKey
} from '../../../utils/HelpFunctions';
import Image from '@jetshop/ui/Image';
import { ImageGalleryRowSlider } from './ImageGalleryRowSlider';
import { useInView } from 'react-intersection-observer';

const ImageGalleryRowWrapper = styled('div')`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 80rem;
  padding: 0 2rem;

  &.full-width {
    max-width: 100%;
    padding: 0;
  }
`;

export const ImageGalleryRow = ({
  imageAspect,
  fullWidth,
  anchorTag,
  children
}) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);
  const anchorProps = createAnchorProps(anchorTag?.value);

  const pageControlsChildren = filterChildrenByKey(
    children,
    'imageGalleryRowPageControls',
    true
  );
  const currentSlideShowerChildren = filterChildrenByKey(
    children,
    'imageGalleryRowCurrentSlideShower',
    true
  );

  const customSlideButtonChildren = filterChildrenByKey(
    children,
    'imageGalleryRowSliderButtons',
    true
  );
  const imageChildren = filterChildrenByKey(
    children,
    'imageGalleryRowItem',
    true
  );

  const modedImageChildren = imageChildren?.map(ic => {
    let childProps = { ...ic.props, imageAspect: imageAspect };
    return { ...ic, props: childProps };
  });

  return (
    <ImageGalleryRowWrapper
      ref={ref}
      {...anchorProps}
      className={cx(fullWidth?.value && 'full-width')}
    >
      <ImageGalleryRowSlider
        desktopSlides={4}
        mobileSlides={2}
        currentSlideChild={currentSlideShowerChildren?.[0]}
        customSlideButtonChild={customSlideButtonChildren?.[0]}
        pageControlsChild={pageControlsChildren?.[0]}
      >
        {modedImageChildren}
      </ImageGalleryRowSlider>
    </ImageGalleryRowWrapper>
  );
};

export const ImageGalleryRowItem = ({ image, alt, imageAspect }) => {
  const imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2];
  return (
    <Image
      src={image?.value}
      sizes={imageSizes}
      aspect={imageAspect?.value}
      alt={alt?.value}
      cover
    />
  );
};
