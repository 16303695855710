import { styled } from 'linaria/react';
import { useVariantFromUrl } from '@jetshop/core/hooks/useProductVariants/useVariantFromUrl';
import { useProductVariants } from '@jetshop/core/hooks/useProductVariants';
import t from '@jetshop/intl';
import Image, { transparentDataImg } from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import { css, cx } from 'linaria';
import React, { useContext } from 'react';
import AddToCartForm from '../ProductPage/AddToCart/AddToCartFormSimple';
import { theme } from '../Theme';
import SaleBadge from '../ui/SaleBadge';
import { SliderContext } from '../ui/SharedSlider';

const wrapperStyling = css`
  font-size: 1rem;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
    display: block;
    background: white;
  }

  .image-wrapper {
    position: relative;
  }

  .product-card-image {
    outline: 1px solid #e0e0e0;
    /* margin-left: 1px;
    ${theme.below.lg} {
      outline: 0;
      border: 1px solid #eaeaea;
      margin-left: 1px;
    } */
  }

  .product-card-detail {
    background: white;
    padding: 0.75em;
    line-height: 1.35;
    text-align: center;
    h3 {
      font-size: 12px;
      font-weight: 300;
      padding: 0 5px;
      color: #333333;
    }

    span {
      letter-spacing: 0.5px;
      display: block;
      text-transform: uppercase;
      font-weight: normal;
      padding: 0 5px;
      font-size: 10px;
      font-weight: 700;
    }
  }
  .package-badge {
    background: black;
    color: white;
    font-size: 14px;
    padding: 3px 6px;
  }
  .price-package-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom:1px;
    * {
      font-size: 12px;
      font-weight: 700;
    }
    .package-price-label {
      font-size: 12px;
      color: grey;
      margin-left: 5px;
    }

    > [data-flight-price] {
      display: flex;
      justify-content: center;
      align-items: center;
      > div {
        margin: 0 3px;
      }
    }

    .new-price {
      color: ${theme.colors.red};
    }
    .old-price {
      color: #767676;
    }
  }

  .badge-top-left {
    top: 10px;
    left: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > div {
      background: black;
      color: white;
      font-size: 9px;
      padding: 0px 5px;
      margin-bottom: 3px;
      letter-spacing: 0.05rem;
      font-weight: bold;

      &.orange {
        background: #ff6600;
        color: white;
      }
      &.blue {
        background: ${theme.colors.accent};
        color: white;
      }
      &.black {
        background: black;
        color: white;
      }
      &.white {
        background: white;
        color: black;
      }
      &.red {
        background: red;
        color: white;
      }
    }
  }
`;

const BuyForm = styled('div')`
  padding: 0 10px;
  margin: auto;
  width: 100%;
  margin-top: -2rem;
  ${theme.below.lg} {
    margin-top: 0;
  }
`;

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 4, 1 / 3, 1 / 2, 1 / 2],
  forwardRef,
  as = 'li',
  children,
  buyable,
  onClick,
  loadImageEagerly,
  ...linkProps
}) {
  const initialVariant = useVariantFromUrl();
  const variantHandler = useProductVariants(product, { initialVariant });
  const {
    selectedVariant: selectedVariation,
    getMissingOptions
  } = variantHandler;

  const hasImages = product?.images && product?.images?.length > 0;

  const Tag = as;

  let badges = product?.badges ? [...product?.badges] : [];
  product?.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  const sliderContext = useContext(SliderContext);

  return (
    <Tag
      className={cx('product-card', wrapperStyling, className)}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductLink
        product={product}
        {...linkProps}
        onClick={e => {
          // prevent click when dragging in slider
          if (onClick) {
            onClick();
          }
          if (sliderContext?.dragging) {
            e.preventDefault();
          }
        }}
      >
        <div className="image-wrapper">
          {product?.price?.incVat < product?.previousPrice?.incVat ? (
            <SaleBadge product={product} />
          ) : null}
          {hasImages ? (
            <Image
              className="product-card-image"
              sizes={imageSizes}
              aspect={imageAspect}
              alt={product.images[0].alt}
              src={product.images[0].url}
              critical={loadImageEagerly}
            >
              <Badges badges={badges} />
            </Image>
          ) : (
            <Image src={transparentDataImg} />
          )}
        </div>
        <section className="product-card-detail">
          <header>
            <span className="sub-name">{product.subName || '\u00A0'}</span>
            <h3>{product.name.replace(product.subName, '')}</h3>
            {/* <h3>{product.name}</h3> */}
          </header>
          <div className="price-package-wrapper">
            <Price
              price={product.price}
              previousPrice={product.previousPrice}
            />
          </div>
        </section>
      </ProductLink>
      {children}
      {buyable && (
        <BuyForm>
          <AddToCartForm
            product={product}
            variant={selectedVariation}
            getMissingOptions={getMissingOptions}
            variantHandler={variantHandler}
          />
        </BuyForm>
      )}
    </Tag>
  );
}
