import AddToCartFormik from '@jetshop/core/components/Mutation/AddToCartFormik';
import t from '@jetshop/intl';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { Form } from 'formik';
import React from 'react';
import { styled } from 'linaria/react';
import addToCartMutation from '../../Cart/addToCart.gql';
import CartQuery from '../../Cart/CartQuery.gql';
import InputWithLabel from '../../Forms/Input';
import Button from '../../ui/Button';
import { useProductValidationMessage } from '../useProductValidationMessage';
import { VariantSelector } from '../VariantSelector';
import { theme } from '../../Theme';
import { useNotification } from '@jetshop/core/components/Notifications';
import ProductToastWrapper from './ProductToast';

const Wrapper = styled('div')`
  .input-check,
  .stock-status {
    display: none !important;
  }
  button {
    font-size: 11px;
    span {
      font-size: 11px;
      padding: 0;
    }
  }

  [data-flight-dropdown-items] {
    max-height: 230px;
    overflow-y: scroll;
    font-size: 11px;

    .stock-status {
      display: block !important;
    }
  }
`;

const BuyButton = styled(Button)`
  background: #048a01;
  color: white;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: bold;
  height: 35px;
  line-height: 35px;
  padding: 0;
  font-size: 14px;
`;

const StyledForm = styled(Form)`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
  ${theme.below.lg} {
    align-items: center;
  }
  .hideThis {
  }
`;

const QuantityAddToCartWrapper = styled('div')`
  display: flex;
  width: 100%;
  align-items: center;
  .quantity-input-wrapper {
    display: none;
  }
  label {
    display: none;
  }
`;

function AddToCartForm({
  product,
  variant,
  getMissingOptions,
  variantHandler
}) {
  // const configurationContext = useContext(ProductConfigurationContext);
  // const price = useDynamicPrice(product, variant);
  const stockStatus = useStockStatus(variant || product);
  const outOfStockNotify = stockStatus.status === 'notifyWhenBack';
  const { validation: variantValidation, missingOptions } = variantHandler;
  const { hasVariants } = product;
  const { validationMessage, enableValidation } = useProductValidationMessage({
    missingOptions,
    variantValidation,
    hasVariants,
    stockStatus
  });
  const [trigger, dismiss] = useNotification();

  const onAddToCartInit = ({ mutationId, quantity, price }) => {
    trigger(
      <ProductToastWrapper
        selectedVariation={variant}
        product={product}
        quantity={quantity}
        price={price}
      />,
      {
        id: mutationId,
        type: 'add-to-cart'
      }
    );
  };
  const onAddToCartError = () => {
    return ({ selectedVariation, mutationId, quantity, price, error }) => {
      dismiss(mutationId);

      trigger(
        <ProductToastWrapper
          selectedVariation={variant}
          product={product}
          quantity={quantity}
          price={price}
          error={error}
        />,
        {
          type: 'add-to-cart'
        }
      );
    };
  };
  return (
    <AddToCartFormik
      onAddToCartInit={onAddToCartInit}
      onAddToCartError={onAddToCartError}
      cartQuery={CartQuery}
      cartMutation={addToCartMutation}
      product={product}
      variant={variant}
      getMissingOptions={getMissingOptions}
      enableReinitialize={true}
    >
      {formData => {
        return (
          <Wrapper>
            {product.hasVariants && (
              <VariantSelector
                product={product}
                variantHandler={variantHandler}
                showValidation={!!validationMessage}
                hideCheck={true}
              />
            )}

            <StyledForm>
              {!outOfStockNotify && (
                <QuantityAddToCartWrapper>
                  <InputWithLabel
                    disableValidation
                    wrapperClassName="quantity-input-wrapper"
                    min="1"
                    type="number"
                    name="quantity"
                    label="Quantity"
                  />
                  <BuyButton
                    data-testid="add-to-cart"
                    type="submit"
                    disabled={!!validationMessage}
                    onClick={enableValidation}
                    style={{
                      opacity: !!validationMessage ? 0.7 : 1,
                      cursor: !!validationMessage ? 'not-allowed' : 'pointer',
                      width: '100%'
                    }}
                  >
                    {validationMessage ? validationMessage : t('Add to cart')}
                  </BuyButton>
                </QuantityAddToCartWrapper>
              )}
            </StyledForm>
          </Wrapper>
        );
      }}
    </AddToCartFormik>
  );
}

export default AddToCartForm;
