import React from 'react';
import { ProductRowCard } from '../CategoryPage/ProductGrid';
import MaxWidth from '../Layout/MaxWidth';
import { SharedSlider } from '../ui/SharedSlider';

import { styled } from 'linaria/react';
import { createAnchorProps } from '../../utils/HelpFunctions';

const MaxWidthWrapper = styled(MaxWidth)`
  padding: 0;

  .slick-list {
    &:after {
      content: '';
      height: 258px;
      width: 1px;
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      background: #e0e0e0;
    }
  }
`;

const RowTitle = styled('h2')`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

export const ProductRow = ({
  title,
  desktopSlideQuantity,
  mobileSlideQuantity,
  anchorTag,
  children
}) => {
  const anchorProps = createAnchorProps(anchorTag?.value);
  return (
    <MaxWidthWrapper {...anchorProps}>
      {title?.value && <RowTitle>{title?.value}</RowTitle>}
      <SharedSlider
        arrowTopPosition={'30%'}
        desktopSlides={desktopSlideQuantity?.value}
        mobileSlides={mobileSlideQuantity?.value}
      >
        {children}
      </SharedSlider>
    </MaxWidthWrapper>
  );
};

export const ProductRowItem = ({ product }) => {
  if (!product) return null;
  return (
    <ProductRowCard
      product={product?.value}
      listName={'Product slider row'}
      loading={false}
      buyable={false}
    />
  );
};
