import React, { useEffect } from 'react';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useMutation, useQuery } from '@apollo/react-hooks';
import changeClubMutation from './changeClubQuery.gql';
import dynamicsQuery from './customerDynamicsQuery.gql';

const ClubActivator = () => {
  const { data, loading, error } = useQuery(dynamicsQuery);
  const [activateClub] = useMutation(changeClubMutation);

  useEffect(() => {
    if (data) {
      const dynamicContent = JSON.parse(data?.customer?.dynamicContent);
      if (dynamicContent?.club_active) return;
      let billing = {
        ...data?.customer?.billingAddress,
        countryCode: data.customer.billingAddress.country.code
      };

      delete billing.__typename;
      delete billing.country;
      activateClub({
        variables: {
          groupCode: dynamicContent?.club_code,
          input: {
            dynamicContent: JSON.stringify({
              ...dynamicContent,
              club_active: true
            }),
            billingAddress: billing
          }
        }
      });
    }
  }, [data, loading, error]);

  return null;
};

export const ClubActivatorChecker = () => {
  const { loggedIn } = useAuth();

  if (loggedIn) return <ClubActivator />;

  return null;
};
