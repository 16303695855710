/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theme';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';
import FreeShipping from './FreeShipping';
import DynamicCategoryContent from '../ui/DynamicCategoryContent';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import removeFromCartMutation from './removeFromCart.gql';

const Flyout = styled('div')`
  background: white;
  color: ${theme.colors.black};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;

  > div {
    width: 100%;
    max-height: calc(100% - 117px);
    overflow-y: scroll;
  }
  > section {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  ${theme.above.sm} {
    position: absolute;
    z-index: 999;
    right: 0;
    top: 0;
    width: 22rem;
    min-width: 19rem;
    max-width: 100%;
  }
`;

const CartItems = styled('section')`
  a {
    color: #333333;
    text-decoration: none;
    line-height: 1.2;
  }
`;

const Summary = styled('section')`
  padding: 1rem;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }

  h2 {
    font-weight: 500;
    font-size: 1.2em;
  }

  .discounts {
    margin-bottom: 1em;
    h2 {
      margin-bottom: 1em;
    }
  }

  .cart-total {
    padding-top: 1em;
    .shipping,
    .total,
    .vat {
      display: flex;
      justify-content: center;
      align-items: center;

      > label {
        margin: 0 3px;
      }
      > div {
        margin: 0 3px;
      }
    }

    .total {
      margin: 0 3px;
      font-size: 1rem;
      font-weight: 600;
      color: ${theme.colors.black};
    }
  }
`;

const Checkout = styled('a')`
  width: 100%;
  display: flex;
  color: white;
  background: #048a01;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: block;
  text-decoration: none;
  font-weight: 600;
  margin-top: 1rem;
  font-size: 15px;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
`;

const CartUsp = styled('div')`
  text-align: center;
  width: 100%;
  display: block;
  margin-top: 10px;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    li {
      font-size: 11px;
      margin: 0 5px;
    }
  }
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = result?.data?.cart?.items ?? [];
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });
  const track = useTracker();
  useEffect(() => {
    items.some(item => {
      //Check if hidden-item
      if (
        item?.customerComments?.length > 0 &&
        item.customerComments[0].name.toLowerCase().indexOf('print') > -1
      ) {
        const depends_on = item.customerComments[0].value.split('-')[0].trim();
        //Is dependent item in cart?
        if (!items.find(checkItem => checkItem.articleNumber === depends_on)) {
          removeFromCart({ itemId: item.id, product: item.product });
          return true;
        }
      }
    });
  }, [items.length]);

  if (items.length === 0) {
    return (
      <Flyout {...rest}>
        <div style={{ padding: '2em' }}>{t('No items in cart.')}</div>
      </Flyout>
    );
  }

  return (
    <Flyout {...rest}>
      <div>
        <CartItems>
          {items.map(item => {
            const hide = item.customerComments.find(
              comment => comment.name === 'hideThis'
            );
            return (
              <React.Fragment key={item.id}>
                {hide ? null : (
                  <CartItem item={item} key={item.id} allItems={items} />
                )}
              </React.Fragment>
            );
          })}
        </CartItems>
      </div>
      <Summary>
        <div className="cart-total">
          <div className="total">
            <label>Totalt:</label>
            <Price price={result.data.cart.productTotal} />
          </div>

          <FreeShipping
            className="shipping"
            cartTotal={result.data.cart.productTotal}
          />
          {checkoutUrl && (
            <Checkout
              href={checkoutUrl}
              onClick={() => {
                track(trackCartCheckoutEvent({ cart: result.data.cart }));
              }}
            >
              {t('Check out')}
            </Checkout>
          )}
          <CartUsp>
            <DynamicCategoryContent id={162} />
          </CartUsp>
        </div>
      </Summary>
    </Flyout>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <DrawerTarget id="cart-drawer">
          {drawer => (
            <Drawer isOpen={drawer.isOpen} right>
              <CartFlyoutView modal={drawer} result={result} {...props} />
            </Drawer>
          )}
        </DrawerTarget>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
