import React from 'react';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { HtmlRow } from '../HtmlRow';

export const MembersHtmlRow = ({ loggedIn, children, ...props }) => {
  const auth = useAuth();
  const isLoggedIn = auth?.loggedIn;

  //Generall (not logged in)
  if (!loggedIn?.value && !isLoggedIn) {
    return <HtmlRow {...props}>{children}</HtmlRow>;
  }

  //Logged in
  if (loggedIn?.value && isLoggedIn) {
    return <HtmlRow {...props}>{children}</HtmlRow>;
  }

  return null;
};
