import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import React from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { theme } from '../Theme';

const CampaignBarContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100vw;

  .campaign-bar-item {
    --parts: ${props => props.parts};
    width: calc(100% / var(--parts));
  }
`;
const CampaignBarItemContainer = styled('div')`
  background: ${props => props.backgroundcolor};

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 34px;
  padding: 0 0.5rem;

  ${theme.below.md} {
    min-height: 34px;
    padding: 0px 30px;
  }

  a,
  p {
    /* font-family: Source Sans Pro; */
    font-style: normal;
    font-size: 11px;
    line-height: 11px;
    text-decoration: none;
    text-align: center;
    color: ${props => props.textcolor}!important;
  }
`;

const CloseBtn = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  .icon-wrapper {
    margin-right: 11px;
    background: white;
    padding: 4px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 9px !important;
      height: auto;
      use {
        fill: #666666;
      }
    }
  }
`;

const SliderContainer = styled('div')`
  position: relative;
  width: 100%;
  max-width: 100vw;
`;

export const CampaignBarItem = ({ backgroundColor, link, text, textColor }) => {
  return (
    <CampaignBarItemContainer
      backgroundcolor={backgroundColor?.value}
      textcolor={textColor?.value}
      className="campaign-bar-item"
    >
      {link?.value?.length > 0 ? (
        <Link
          to={link?.value}
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      ) : (
        <p
          dangerouslySetInnerHTML={{
            __html: text?.value
          }}
        />
      )}
    </CampaignBarItemContainer>
  );
};

export const CampaignBar = ({ closeCampaignBarOption, children }) => {
  const [cookies, setCookie] = useCookies();
  const hideCampaignBar = cookies?.hideCampaignBar;
  const showCloseBtn = closeCampaignBarOption?.value === 'YES';

  const mobileSettings = {
    dots: false,
    infinite: true,
    speed: 600,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const handleCloseClick = () => {
    setCookie('hideCampaignBar', true, [{ path: '/' }]);
  };

  return (
    <>
      {!hideCampaignBar && (
        <Above breakpoint="lg">
          {matches =>
            matches ? (
              <CampaignBarContainer parts={children?.length ?? 1}>
                {showCloseBtn && (
                  <CloseBtn
                    onClick={() => {
                      handleCloseClick();
                    }}
                  >
                    <div className={'icon-wrapper'}>
                      <Cross />
                    </div>
                  </CloseBtn>
                )}

                {children}
              </CampaignBarContainer>
            ) : (
              <SliderContainer>
                {showCloseBtn && (
                  <CloseBtn
                    onClick={() => {
                      handleCloseClick();
                    }}
                  >
                    <div className={'icon-wrapper'}>
                      <Cross />
                    </div>
                  </CloseBtn>
                )}
                <Slider {...mobileSettings}>{children}</Slider>
              </SliderContainer>
            )
          }
        </Above>
      )}
    </>
  );
};
