import React from 'react';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image';
import { Link } from 'react-router-dom';
import { FakeButton } from '../StartPage/Content/Row';
import { createAnchorProps } from '../../utils/HelpFunctions';

const TopBlockWrapper = styled('div')`
  padding: 0;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(4, 10vw);
  grid-column-gap: ${props => props.gap};
  grid-row-gap: ${props => props.gap};
  max-width: 80rem;
  width: 100%;

  ${theme.above.xl} {
    &:not(.fullwidth) {
      grid-template-rows: repeat(4, 8rem);
    }
  }

  ${theme.below.lg} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 40vw);
  }

  &.fullwidth {
    max-width: 100%;
  }

  &.items-4 {
    .div-one {
      grid-area: 1 / 1 / 5 / 5;
    }
    .div-two {
      grid-area: 1 / 5 / 3 / 9;
    }
    .div-three {
      grid-area: 3 / 5 / 5 / 7;
    }
    .div-four {
      grid-area: 3 / 7 / 5 / 9;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-two {
        grid-area: 3 / 1 / 4 / 3;
      }
      .div-three {
        grid-area: 4 / 1 / 5 / 2;
      }
      .div-four {
        grid-area: 4 / 2 / 5 / 3;
      }
    }
  }

  &.items-3 {
    .div-one {
      grid-area: 1 / 1 / 5 / 5;
    }
    .div-two {
      grid-area: 1 / 5 / 3 / 9;
    }
    .div-three {
      grid-area: 3 / 5 / 5 / 9;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-two {
        grid-area: 3 / 1 / 4 / 3;
      }
      .div-three {
        grid-area: 4 / 1 / 5 / 3;
      }
    }
  }

  &.items-2 {
    .div-one {
      grid-area: 1 / 1 / 5 / 5;
    }
    .div-two {
      grid-area: 1 / 5 / 5 / 9;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 3 / 3;
      }
      .div-two {
        grid-area: 3 / 1 / 5 / 3;
      }
    }
  }

  &.items-1 {
    .div-one {
      grid-area: 1 / 1 / 5 / 9;
    }
    ${theme.below.lg} {
      .div-one {
        grid-area: 1 / 1 / 5 / 3;
      }
    }
  }
`;

const TopBlockItemWrapper = styled('div')``;

export const TopBlock = ({ fullWidth, gap, anchorTag, children }) => {
  const anchorProps = createAnchorProps(anchorTag?.value);
  const childClassName = children?.length;

  // fix for Safari
  const numberToText = number => {
    switch (number) {
      case 1:
        return 'one';
      case 2:
        return 'two';
      case 3:
        return 'three';
      case 4:
        return 'four';

      default:
        return 'number-not-found';
    }
  };

  return (
    <TopBlockWrapper
      {...anchorProps}
      gap={gap?.value ? '3px' : '0px'}
      className={cx('items-' + childClassName, fullWidth?.value && 'fullwidth')}
    >
      {children?.map((child, index) => {
        return (
          <TopBlockItemWrapper
            key={index + 'top-block-item'}
            className={cx(
              'div-' + numberToText(index + 1),
              fullWidth?.value && 'fullwidth'
            )}
          >
            <TopBlockItem
              blockNumber={numberToText(index + 1)}
              index={index}
              {...child?.props}
            ></TopBlockItem>
          </TopBlockItemWrapper>
        );
      })}
    </TopBlockWrapper>
  );
};

const TopBlockItemLinkContainer = styled(Link)`
  width: 100%;
  height: 100%;
  text-decoration: none;
`;

const TopBlockItemExternalLinkContainer = styled('a')`
  width: 100%;
  height: 100%;
  text-decoration: none;
`;
const TopBlockItemContainer = styled('div')`
  width: 100%;
  height: 100%;
  text-decoration: none;
`;

const BlockContent = styled('div')`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: white;
  padding: 40px;

  ${theme.below.lg} {
    padding: 20px;
  }

  h3 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    line-height: 1;
    font-weight: 400;
    ${theme.below.lg} {
      font-size: 11px;
    }
  }
  h2 {
    font-size: 35px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    line-height: 1;
    ${theme.below.lg} {
      font-size: 16px;
    }
  }

  &.large {
    top: 38px;
    transform: none;
    bottom: auto;
    max-width: 450px;
    ${theme.below.lg} {
      top: 20px;
    }
    h3 {
      font-size: 18px;
      ${theme.below.lg} {
        font-size: 14px;
      }
    }
    h2 {
      font-size: 50px;
      ${theme.below.lg} {
        font-size: 35px;
      }
    }
  }
`;

const imageTransformStyle = css`
  img {
    transition: all, 0.5s ease !important;
  }
  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;

export const TopBlockItem = ({
  blockNumber,
  index,
  blockLink,
  image,
  smallTitle,
  title,
  buttonText,
  buttonTextColor,
  buttonBackgroundColor
}) => {
  const buttonProps = {
    background: buttonBackgroundColor?.value ?? '#ffffff',
    color: buttonTextColor?.value ?? '#006EB6',
    backgroundhover: buttonTextColor?.value ?? '#006EB6',
    colorhover: buttonBackgroundColor?.value ?? '#ffffff',
    bordercolor: '#006EB600'
  };

  const isRelativeUrl = url => {
    return url && !url.startsWith('http://') && !url.startsWith('https://');
  };

  const sizes = {
    one: '800',
    two: '800',
    three: '400',
    four: '400'
  };

  const blockImageAndContent = () => {
    return (
      <Image
        sizes={sizes[blockNumber]}
        src={image?.value}
        fillAvailableSpace
        quality={100}
        critical
        aspect="1:1"
      >
        <BlockContent>
          <h3>{smallTitle?.value}</h3>
          <h2>{title?.value}</h2>
          {buttonText?.value && (
            <FakeButton {...buttonProps}>{buttonText?.value}</FakeButton>
          )}
        </BlockContent>
      </Image>
    );
  };

  const linkValue = blockLink?.value;
  return (
    <>
      {blockLink?.value?.length > 0 ? (
        <>
          {!isRelativeUrl(linkValue) ? (
            <TopBlockItemExternalLinkContainer
              href={blockLink?.value}
              className={imageTransformStyle}
            >
              {blockImageAndContent()}
            </TopBlockItemExternalLinkContainer>
          ) : (
            <TopBlockItemLinkContainer
              to={blockLink?.value}
              className={imageTransformStyle}
            >
              {blockImageAndContent()}
            </TopBlockItemLinkContainer>
          )}
        </>
      ) : (
        <TopBlockItemContainer className={imageTransformStyle}>
          {blockImageAndContent()}
        </TopBlockItemContainer>
      )}
    </>
  );
};
