import ChannelContext from '@jetshop/core/components/ChannelContext';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Query } from 'react-apollo';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import NewsletterField from '../../Newsletter/NewsletterField';
import { theme } from '../../Theme';
import FooterQuery from './FooterQuery.gql';
import { ReactComponent as Facebook } from '../../../svg/Facebook-logo.svg';
import { ReactComponent as Instagram } from '../../../svg/Instagram-logo.svg';
import { ReactComponent as Twitter } from '../../../svg/Twitter-logo.svg';
import { ReactComponent as Klarna } from '../../../svg/Klarna.svg';
import { ReactComponent as Visa } from '../../../svg/Visa.svg';
import { ReactComponent as Mastercard } from '../../../svg/Mastercard.svg';
import { ReactComponent as Bring } from '../../../svg/Bring.svg';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { useLocation } from 'react-router';
import footerStoresQuery from './FooterStoresQuery.gql';

const PoweredByWrapper = styled('div')`
  text-align: center;
  margin: 20px auto 20px auto;
`;

const Wrapper = styled('section')`
  padding: 50px 20px;
  width: 100%;
  display: block;
  max-width: 1280px;
  margin: auto;
  ${theme.below.lg} {
    background: #f5f5f5;
    padding: 10px 40px;
  }

  .seo-text {
    font-size: 11px;
    line-height: 18px;
    ${theme.below.lg} {
      margin-top: 20px;
    }
    p {
      font-size: 11px;
      line-height: 18px;
    }
  }
`;

const NewsletterWrapper = styled('div')`
  background: ${theme.colors.accent};
  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &.brann-newsletter {
    background: ${theme.colors.brann};
  }
`;
const NewsletterInner = styled('div')`
  max-width: 1000px;
  margin: auto;
  padding: 100px 20px;
  color: white;
  ${theme.below.lg} {
    padding: 20px 40px 0 40px;
    text-align: center;
  }

  .cta {
    display: flex;
    justify-content: center;
    margin: 40px 0 0 0;

    ${theme.below.lg} {
      margin: 40px 0;
    }

    a {
      color: white;
      padding: 10px 40px;
      background: #005c95;
      border: 0;
      outline: none;
      border-radius: 30px;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer;
    }
  }

  > div {
    align-items: center;
    ${theme.below.lg} {
      display: block;
      border: 0;
    }
    > div {
      margin: 0;
      padding: 10px 20px;
      ${theme.below.lg} {
        padding: 0;
      }
      h2 {
        font-size: 2.5rem;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        margin-bottom: 20px;
        ${theme.below.lg} {
          font-size: 30px;
          margin-bottom: 10px;
        }
      }
      p {
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 25px;
        color: white;
        ${theme.below.lg} {
          font-size: 13px;
          line-height: 1.6;
        }
      }
    }
  }
`;

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${theme.below.lg} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`;
const Column = styled('div')`
  flex: 1;
  padding: 10px;
  margin-bottom: 30px;
  ${theme.below.lg} {
    margin: 0 20px 0 0;
    padding: 15px 0;
    &:last-child {
      margin-right: 0;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
  &.small {
    max-width: 200px;
  }
  &.large {
    flex: 2;
    max-width: 600px;
  }
  &.payment {
    text-align: right;
    svg {
      display: inline-block;
      margin: 0 0 0 5px;
      max-height: 30px;
      max-width: 45px;
    }
    ${theme.below.lg} {
      text-align: center;
      margin: 0 3px;
    }
  }

  h2 {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }
  li,
  a,
  p {
    font-size: 13px;
    color: #333333;
    text-decoration: none;
  }
`;

const SocialsWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${theme.below.lg} {
    justify-content: flex-end;
  }
  a {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.accent};
    margin-right: 6px;
    svg {
      height: 15px;
      use,
      path {
        fill: white;
      }
    }
  }
`;

const PhoneColumn = styled('div')`
  max-width: 160px;
  margin-top: 30px;
  ${theme.below.lg} {
    margin: 0;
  }
  span {
    display: block;
    font-size: 22px;
    color: ${theme.colors.accent};
    letter-spacing: 0.05em;
    font-weight: bold;
    &.small {
      font-size: 13px;
      line-height: 1.3;
      font-weight: normal;
      color: #333333;
      ${theme.below.lg} {
        font-size: 12px;
      }
    }
  }
`;

const AccordionItem = styled('div')`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;
const AccordionTitle = styled('div')`
  font-size: 13px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  > a {
    color: ${theme.colors.black};
    text-decoration: none;
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 2rem;
`;
const AccordionContentInner = styled('div')`
  padding-bottom: 20px;
  ul {
    margin-bottom: 20px;
  }
  p,
  li,
  a {
    font-size: 13px;
    font-weight: normal;
    color: #333333;
    text-decoration: none;
  }
`;

const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>{isOpen ? '–' : '+'}</IndicatorIcon>
);

const CategoryColumn = ({ id, title }) => (
  <Query query={FooterQuery} variables={{ id }}>
    {({ loading, error, data }) => {
      if (loading || error) return null;
      return (
        <>
          {data.category && (
            <React.Fragment>
              {data.category.subcategories.length > 0 ? (
                <>
                  <Above breakpoint="lg">
                    <h2>{title ? title : data.category.name}</h2>
                  </Above>
                  <ul>
                    {data.category.subcategories.map((subcat, i) => (
                      <li key={i}>
                        <CategoryLink category={subcat}>
                          {subcat.name}
                        </CategoryLink>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.category.content
                  }}
                />
              )}
            </React.Fragment>
          )}
        </>
      );
    }}
  </Query>
);

export const Socials = () => {
  const location = useLocation();

  const soMeLinks = {
    '': {
      facebook: 'https://www.facebook.com/torshovfotball/',
      instagram: 'https://www.instagram.com/torshovfotball/',
      twitter: 'https://twitter.com/torshovfotball'
    },
    fotball: {
      facebook: 'https://www.facebook.com/torshovfotball/',
      instagram: 'https://www.instagram.com/torshovfotball/',
      twitter: 'https://twitter.com/torshovfotball'
    },
    lop: {
      facebook: 'https://www.facebook.com/TorshovLop/',
      instagram: 'https://www.instagram.com/torshovlop/',
      twitter: ''
    },
    klubbservice: { facebook: '', instagram: '', twitter: '' },
    'andre-idretter': {
      facebook: '',
      instagram: '',
      twitter: ''
    },
    hockey: {
      facebook: 'https://www.facebook.com/torshovhockey/',
      instagram: 'https://www.instagram.com/torshovhockey/',
      twitter: ''
    },
    brann: {
      facebook: 'https://www.facebook.com/sportsklubbenbrann/',
      instagram: 'https://www.instagram.com/sportsklubbenbrann/',
      twitter: 'https://twitter.com/skbrann'
    }
  };

  const soMeLinkCreator = soMe => {
    const pathNameArray = location?.pathname?.split('/');
    const pathName = pathNameArray[1] ?? '';
    const linkGroup = soMeLinks[pathName] ?? { '': '' };
    const link = linkGroup[soMe] ?? '';
    return link;
  };

  const instagramLink = soMeLinkCreator('instagram');
  const facebookLink = soMeLinkCreator('facebook');
  const twitterLink = soMeLinkCreator('twitter');

  return (
    <SocialsWrapper>
      {facebookLink.length > 0 && (
        <a href={facebookLink} target={'_blank'} rel="noreferrer">
          <Facebook />
        </a>
      )}
      {instagramLink.length > 0 && (
        <a href={instagramLink} target={'_blank'} rel="noreferrer">
          <Instagram />
        </a>
      )}
      {twitterLink.length > 0 && (
        <a href={twitterLink} target={'_blank'} rel="noreferrer">
          <Twitter />
        </a>
      )}
    </SocialsWrapper>
  );
};

const NewsletterComponent = ({ selectedChannel }) => {
  return (
    <NewsletterWrapper
      className={selectedChannel.id === 7 ? 'brann-newsletter' : ''}
    >
      <NewsletterInner>
        <Row>
          <Column>
            <h2>{t('Nyhetsbrev')}</h2>
            <p>
              Hver uke sender vi ut nyheter, tips og annen informasjon gjennom
              vårt nyhetsbrev. Vil du være først ute?
            </p>
          </Column>
          <Column>
            <div className="newsletter-container">
              <NewsletterField />
            </div>
          </Column>
        </Row>
      </NewsletterInner>
    </NewsletterWrapper>
  );
};

const MembershipComponent = () => {
  return (
    <NewsletterWrapper>
      <NewsletterInner>
        <Row>
          <Column className="column">
            <h2>{t('Become a member')}</h2>
            <p>
              Få tilgang til unike fordeler i butikk og på nett som medlem av
              kundeklubben Team Torshov.
            </p>
            <div className="cta">
              <Link to="/signup">REGISTRER</Link>
            </div>
          </Column>
        </Row>
      </NewsletterInner>
    </NewsletterWrapper>
  );
};

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);

  const { data, loading, error } = useQuery(footerStoresQuery);
  if (loading || error) return null;
  var stores = {
    oslo: [],
    norway: []
  };

  const storeList = data?.stores.filter(
    store =>
      store.name !== 'Torshov Sport Oslo' &&
      store.name !== 'Torshov Sport Soria Moria'
  );

  for (let i = 0; i < storeList.length; i++) {
    const store = storeList[i];

    if (store.id === 25) continue;
    if (store.city === 'Oslo') {
      stores.oslo.push(store);
    } else {
      stores.norway.push(store);
    }
  }

  return (
    <>
      {/* <NewsletterComponent selectedChannel={selectedChannel} /> */}
      <MembershipComponent />
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <Wrapper>
              <Row>
                <Column className="small">
                  <Link to="/butikker">
                    <h2>VÅRE BUTIKKER OG ÅPNINGSTIDER</h2>
                  </Link>

                  <Accordion single>
                    {({ openIndexes, handleClick, AccordionContent }) => (
                      <>
                        <AccordionItem>
                          <AccordionTitle onClick={() => handleClick(0)}>
                            <span>Oslo</span>
                            <AccordionIndicator
                              isOpen={openIndexes.includes(0)}
                            />
                          </AccordionTitle>
                          <AccordionContent isOpen={openIndexes.includes(0)}>
                            <AccordionContentInner>
                              <Link to="/butikker">
                                <ul>
                                  {stores.oslo.map((store, index) => (
                                    <li key={index}>{store.name}</li>
                                  ))}
                                </ul>
                              </Link>
                            </AccordionContentInner>
                          </AccordionContent>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionTitle onClick={() => handleClick(1)}>
                            <span>Norge</span>
                            <AccordionIndicator
                              isOpen={openIndexes.includes(1)}
                            />
                          </AccordionTitle>
                          <AccordionContent isOpen={openIndexes.includes(1)}>
                            <AccordionContentInner>
                              <Link to="/butikker">
                                <ul>
                                  {stores.norway.map((store, index) => (
                                    <li key={index}>{store.name}</li>
                                  ))}
                                </ul>
                              </Link>
                            </AccordionContentInner>
                          </AccordionContent>
                        </AccordionItem>
                      </>
                    )}
                  </Accordion>

                  <PhoneColumn>
                    <span>22 09 20 20</span>
                    <span className="small">
                      Vårt kundsenter holder åpent man-fre 11-16
                    </span>
                  </PhoneColumn>
                </Column>
                <Column className="small">
                  <CategoryColumn id={165} />
                </Column>

                <Column className="large">
                  <Row>
                    <Column>
                      <Socials />
                    </Column>
                    <Column className="payment">
                      <Visa /> <Mastercard /> <Klarna /> <Bring />
                    </Column>
                  </Row>

                  <Column className="seo-text">
                    <CategoryColumn id={173} />
                  </Column>
                </Column>
              </Row>

              <PoweredByWrapper></PoweredByWrapper>
            </Wrapper>
          ) : (
            <Wrapper>
              <Accordion single>
                {({ openIndexes, handleClick, AccordionContent }) => (
                  <>
                    <AccordionItem>
                      <AccordionTitle onClick={() => handleClick(0)}>
                        <Link to="/butikker">
                          <span>VÅRE BUTIKKER OG ÅPNINGSTIDER</span>
                        </Link>
                        <AccordionIndicator isOpen={openIndexes.includes(0)} />
                      </AccordionTitle>
                      <AccordionContent isOpen={openIndexes.includes(0)}>
                        <AccordionContentInner>
                          <Link to="/butikker">
                            <strong>Oslo</strong>
                            <ul>
                              {stores.oslo.map((store, index) => (
                                <li key={index}>{store.name}</li>
                              ))}
                            </ul>

                            <strong>Norge</strong>
                            <ul>
                              {stores.norway.map((store, index) => (
                                <li key={index}>{store.name}</li>
                              ))}
                            </ul>
                          </Link>
                        </AccordionContentInner>
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionTitle onClick={() => handleClick(1)}>
                        <span>Kundeinformasjon</span>
                        <AccordionIndicator isOpen={openIndexes.includes(1)} />
                      </AccordionTitle>
                      <AccordionContent isOpen={openIndexes.includes(1)}>
                        <AccordionContentInner>
                          <CategoryColumn id={165} />
                        </AccordionContentInner>
                      </AccordionContent>
                    </AccordionItem>
                  </>
                )}
              </Accordion>

              <Row>
                <Column>
                  <PhoneColumn>
                    <span>22 09 20 20</span>
                    <span className="small">
                      Vårt kundsenter holder åpent man-fre 11-16
                    </span>
                  </PhoneColumn>
                </Column>

                <Column>
                  <Socials />
                </Column>
              </Row>

              <Row>
                <Column className="payment">
                  <Visa /> <Mastercard /> <Klarna /> <Bring />
                </Column>
              </Row>

              <div className="seo-text">
                <CategoryColumn id={173} />
              </div>

              <PoweredByWrapper id={'jetshop-logo'}></PoweredByWrapper>
            </Wrapper>
          )
        }
      </Above>
    </>
  );
};

export default Footer;
