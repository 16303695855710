/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import {
  createAnchorProps,
  setVariableFromProp
} from '../../utils/HelpFunctions';

const IFrameWrapper = styled('div')`
  position: relative;
  padding-bottom: ${props => props.bottompadding}%;
  margin-bottom: 0px;
  width: 100% !important;
  height: auto !important;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const IFrame = ({ src, additionalProps, ratio = '16:9', anchorTag }) => {
  const [bottomPadding, setBottomPadding] = useState();
  const aspect = setVariableFromProp(ratio);
  const anchorProps = createAnchorProps(anchorTag?.value);
  const additionalPrps = JSON.stringify(setVariableFromProp(additionalProps));
  const iframeRef = useRef(null);

  let aProps = null;
  if (additionalPrps) {
    try {
      aProps = JSON.parse(additionalPrps);
    } catch (e) {
      console.warn('IFrame : invalid format for props');
    }
  }

  const calculatedHeightFromRatio = () => {
    if (aspect) {
      const ratioArray = aspect?.split(':');
      const width = ratioArray[0] ?? null;
      const height = ratioArray[1] ?? null;
      if (width && height) {
        return (iframeRef?.current?.offsetWidth / parseInt(width)) * height;
      }
    }
    return null;
  };

  useEffect(() => {
    if (iframeRef?.current?.offsetWidth && !bottomPadding) {
      const fHeight = calculatedHeightFromRatio();
      setBottomPadding((fHeight / iframeRef?.current?.offsetWidth) * 100);
    }
  }, []);

  return (
    <IFrameWrapper
      {...anchorProps}
      ref={iframeRef}
      bottompadding={bottomPadding}
    >
      <iframe
        title={src?.value}
        {...aProps}
        width="100%"
        height="auto"
        src={src?.value}
      />
    </IFrameWrapper>
  );
};
