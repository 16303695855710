import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useLocation } from 'react-router';
import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React, { Fragment, useContext } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import MaxWidth from '../Layout/MaxWidth';
import LoadingPage from '../LoadingPage';
import StartPageProductGrid from './Content/StartPageProductGrid';
import startPageQuery from './StartPageQuery.gql';
import Row from './Content/Row';
import ProductRow from './Content/ProductRow';
import Title from './Content/Title';
import Categories from './Content/Categories';

import { theme } from '../Theme';
import TopBlock from './Content/TopBlock';
import HtmlBlock from './Content/HtmlBlock';

import { contentEditorBaseComponents } from '../ContentEditor/ContentEditorComponents';

export const startPageComponents = {
  ROW: Row,
  PRODUCTROW: ProductRow,
  TITLE: Title,
  CATEGORIES: Categories,
  TOPBLOCK: TopBlock,
  HTMLBLOCK: HtmlBlock,
  ...contentEditorBaseComponents
};

export const StartPageWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.master-startpage {
    h2.box-title {
      font-size: 50px !important;
      ${theme.below.lg} {
        font-size: 30px !important;
      }
    }
  }
`;

export const MainSectionWrapper = styled(MaxWidth)`
  width: 100%;
  align-self: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  ${theme.below.sm} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const StartPage = ({ startPageId }) => {
  const location = useLocation();
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <Fragment>
      <StartPageWrapper
        className={
          location.pathname === '/' &&
          selectedChannel.id !== 7 &&
          'master-startpage'
        }
      >
        <Query
          variables={true && { startPageId: startPageId }}
          query={startPageQuery}
        >
          {result => {
            const { loading, data } = result;
            if (loading) return <LoadingPage />;
            if (!data) return null;
            if (
              data.startPage &&
              data.startPage.items &&
              data.startPage.items.length > 0
            ) {
              return (
                <StartPageRenderer
                  result={result}
                  startPageComponents={startPageComponents}
                  ProductGrid={StartPageProductGrid}
                  LoadingPage={LoadingPage}
                />
              );
            } else if (data?.startPage?.data?.items?.length > 0) {
              return (
                <StartPageRenderer
                  result={result}
                  startPageComponents={startPageComponents}
                  ProductGrid={StartPageProductGrid}
                  LoadingPage={LoadingPage}
                />
              );
            } else return null;
          }}
        </Query>
      </StartPageWrapper>
    </Fragment>
  );
};

export default StartPage;
