import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Above } from '@jetshop/ui/Breakpoints';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import FooterQuery from './FooterQuery.gql';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { Socials } from './Footer';

const PoweredByWrapper = styled('div')`
  text-align: center;
  margin: 20px auto 20px auto;
`;
const Outer = styled('div')`
  background: #f5f5f5;
`;
const Wrapper = styled('section')`
  padding: 30px 20px;
  width: 100%;
  display: block;
  max-width: 1280px;
  margin: auto;
  ${theme.below.lg} {
    padding: 10px 40px;
  }

  .seo-text {
    font-size: 11px;
    line-height: 18px;
    ${theme.below.lg} {
      margin-top: 20px;
    }
    p {
      font-size: 11px;
      line-height: 18px;
    }
  }
`;

const Row = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ${theme.below.lg} {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`;
const Column = styled('div')`
  flex: 1;
  padding: 10px;
  margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
  ${theme.below.lg} {
    margin: 0 20px 0 0;
    padding: 15px 0;
    &:last-child {
      margin-right: 0;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
  &.small {
    max-width: 200px;
  }
  &.large {
    flex: 2;
    max-width: 600px;
  }
  &.payment {
    text-align: right;
    svg {
      display: inline-block;
      margin: 0 0 0 5px;
      max-height: 30px;
      max-width: 45px;
    }
    ${theme.below.lg} {
      text-align: center;
      margin: 0 3px;
    }
  }

  h2 {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }
  li,
  a,
  p {
    font-size: 13px;
    color: #333333;
    text-decoration: none;
  }
`;

const PhoneColumn = styled('div')`
  max-width: 300px;
  margin-bottom: 50px;
  ${theme.below.lg} {
    margin: 0;
  }
  span {
    display: block;
    font-size: 22px;
    color: ${theme.colors.accent};
    letter-spacing: 0.05em;
    font-weight: bold;
    &.small {
      font-size: 13px;
      line-height: 1.3;
      font-weight: normal;
      color: #333333;
      ${theme.below.lg} {
        font-size: 12px;
      }
    }
  }
`;

const AccordionItem = styled('div')`
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;
const AccordionTitle = styled('div')`
  font-size: 13px;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  > a {
    color: ${theme.colors.black};
    text-decoration: none;
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 2rem;
`;
const AccordionContentInner = styled('div')`
  padding-bottom: 20px;
  ul {
    margin-bottom: 20px;
  }
  p,
  li,
  a {
    font-size: 13px;
    font-weight: normal;
    color: #333333;
    text-decoration: none;
  }
`;

const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>{isOpen ? '–' : '+'}</IndicatorIcon>
);

const CategoryColumn = ({ id, title }) => (
  <Query query={FooterQuery} variables={{ id }}>
    {({ loading, error, data }) => {
      if (loading || error) return null;
      return (
        <>
          {data.category && (
            <React.Fragment>
              {data.category.subcategories.length > 0 ? (
                <>
                  <Above breakpoint="lg">
                    <h2>{title ? title : data.category.name}</h2>
                  </Above>
                  <ul>
                    {data.category.subcategories.map((subcat, i) => (
                      <li key={i}>
                        <CategoryLink category={subcat}>
                          {subcat.name}
                        </CategoryLink>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: data.category.content
                  }}
                />
              )}
            </React.Fragment>
          )}
        </>
      );
    }}
  </Query>
);

const Footer = () => (
  <Outer>
    <Above breakpoint="lg">
      {matches =>
        matches ? (
          <Wrapper>
            <Row>
              <Column className="small">
                <Link to="/butikker">
                  <h2>VÅRE BUTIKKER OG ÅPNINGSTIDER</h2>
                </Link>

                <Accordion single>
                  {({ openIndexes, handleClick, AccordionContent }) => (
                    <>
                      <AccordionItem>
                        <AccordionTitle onClick={() => handleClick(0)}>
                          <span>Oslo</span>
                          <AccordionIndicator
                            isOpen={openIndexes.includes(0)}
                          />
                        </AccordionTitle>
                        <AccordionContent isOpen={openIndexes.includes(0)}>
                          <AccordionContentInner>
                            <Link to="/butikker">
                              <ul>
                                <li>
                                  <span>Torshov Sport Fotball</span>
                                </li>
                                <li>
                                  <span>Torshov Sport Klubbservice</span>
                                </li>
                                <li>
                                  <span>Torshov Sport Hockey</span>
                                </li>
                                <li>
                                  <span>Torshov Sport Løp</span>
                                </li>
                                <li>
                                  <span>Torshov Sport Sandvika</span>
                                </li>
                              </ul>
                            </Link>
                          </AccordionContentInner>
                        </AccordionContent>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionTitle onClick={() => handleClick(1)}>
                          <span>Norge</span>
                          <AccordionIndicator
                            isOpen={openIndexes.includes(1)}
                          />
                        </AccordionTitle>
                        <AccordionContent isOpen={openIndexes.includes(1)}>
                          <AccordionContentInner>
                            <Link to="/butikker">
                              <ul>
                                <li>
                                  <span>Torshov Sport Drammen</span>
                                </li>
                                <li>
                                  <span>Torshov Sport Tromsø</span>
                                </li>
                                <li>
                                  <span>Torshov Sport Fredrikstad</span>
                                </li>
                                <li>
                                  <span>Torshov Sport Bergen</span>
                                </li>
                              </ul>
                            </Link>
                          </AccordionContentInner>
                        </AccordionContent>
                      </AccordionItem>
                    </>
                  )}
                </Accordion>
              </Column>

              <Column className="small">
                <PhoneColumn>
                  <h2>Kundeinformasjon</h2>
                  <span>22 09 20 20</span>
                  <span className="small">
                    Vårt kundsenter holder åpent man-fre 11-16
                  </span>
                </PhoneColumn>

                <Socials />
              </Column>
            </Row>

            <PoweredByWrapper></PoweredByWrapper>
          </Wrapper>
        ) : (
          <Wrapper>
            <Accordion single>
              {({ openIndexes, handleClick, AccordionContent }) => (
                <>
                  <AccordionItem>
                    <AccordionTitle onClick={() => handleClick(0)}>
                      <Link to="/butikker">
                        <span>VÅRE BUTIKKER OG ÅPNINGSTIDER</span>
                      </Link>
                      <AccordionIndicator isOpen={openIndexes.includes(0)} />
                    </AccordionTitle>
                    <AccordionContent isOpen={openIndexes.includes(0)}>
                      <AccordionContentInner>
                        <Link to="/butikker">
                          <strong>Oslo</strong>
                          <ul>
                            <li>
                              <span>Torshov Sport Fotball</span>
                            </li>
                            <li>
                              <span>Torshov Sport Klubbservice</span>
                            </li>
                            <li>
                              <span>Torshov Sport Hockey</span>
                            </li>
                            <li>
                              <span>Torshov Sport Løp</span>
                            </li>
                            <li>
                              <span>Torshov Sport Sandvika</span>
                            </li>
                          </ul>

                          <strong>Norge</strong>
                          <ul>
                            <li>
                              <span>Torshov Sport Drammen</span>
                            </li>
                            <li>
                              <span>Torshov Sport Tromsø</span>
                            </li>
                            <li>
                              <span>Torshov Sport Fredrikstad</span>
                            </li>
                            <li>
                              <span>Torshov Sport Bergen</span>
                            </li>
                          </ul>
                        </Link>
                      </AccordionContentInner>
                    </AccordionContent>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionTitle onClick={() => handleClick(1)}>
                      <span>Kundeinformasjon</span>
                      <AccordionIndicator isOpen={openIndexes.includes(1)} />
                    </AccordionTitle>
                    <AccordionContent isOpen={openIndexes.includes(1)}>
                      <AccordionContentInner>
                        <CategoryColumn id={165} />
                      </AccordionContentInner>
                    </AccordionContent>
                  </AccordionItem>
                </>
              )}
            </Accordion>

            <Row>
              <Column>
                <PhoneColumn>
                  <span>22 09 20 20</span>
                  <span className="small">
                    Vårt kundsenter holder åpent man-fre 11-16
                  </span>
                </PhoneColumn>
              </Column>

              <Column>
                <Socials />
              </Column>
            </Row>

            <PoweredByWrapper></PoweredByWrapper>
          </Wrapper>
        )
      }
    </Above>
  </Outer>
);

export default Footer;
