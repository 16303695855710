import { Notifications } from '../Notifications';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import React, { useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import CartFlyout from '../../Cart/CartFlyout';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import MobileMenu from './MobileMenu';
import { SearchBar } from './Search/SearchBar';
import { ReactComponent as LogoWide } from '../../../svg/LogoWide.svg';
import { ReactComponent as LogoSmallWhite } from '../../../svg/LogoSmallWhite.svg';

import { theme } from '../../Theme';

const Container = styled('header')`
  background-color: ${theme.colors.primary};
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;

  + main {
    padding-top: 77px;
    padding-bottom: 0;
  }
  ${theme.below.lg} {
    padding: 0;
    + main {
      padding-top: 61px;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  .top-bar + div + div.left {
    top: 85px;
  }

  &.scrolled {
    .top-bar {
      height: 0;
      overflow: hidden;

      + div + div.left {
        top: 60px;
      }
    }
  }

  a,
  a:visited {
    color: #333333;
  }
`;

const HeaderWrapper = styled('div')`
  padding: 0;
  ${theme.below.lg} {
    padding: 10px 0;
  }
`;
const HeaderInner = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 1288px;
  margin: auto;
  ${theme.below.lg} {
    padding: 0 10px;
  }
`;
const HeaderColumn = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;

  ${theme.below.lg} {
    flex: none;
  }

  &.left {
    justify-content: flex-start;
    text-align: left;
  }
  &.center {
    justify-content: center;
    flex: 2;
    text-align: center;
    flex-wrap: wrap;
  }
  &.right {
    justify-content: flex-end;
    text-align: right;
    &.menu-open {
      display: none;
    }
  }
`;
const LogoWrapper = styled('div')`
  ${theme.below.lg} {
    svg {
      width: 50px;
      height: 40px;
    }
  }

  a {
    display: block;
  }
  img {
    max-width: 100%;
  }
`;

const TopHeader = ({ mobileCategories, categories }) => {
  return (
    <>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <HeaderWrapper>
                <HeaderInner>
                  <HeaderColumn className="left">
                    <LogoWrapper>
                      <Link to="/">
                        <LogoWide />
                      </Link>
                    </LogoWrapper>
                  </HeaderColumn>

                  <HeaderColumn className="center">
                    <SearchBar />
                  </HeaderColumn>

                  <HeaderColumn className="right"></HeaderColumn>
                </HeaderInner>
              </HeaderWrapper>
            </>
          ) : (
            <>
              <DrawerTrigger preventOverflow={true} id="menu-drawer">
                {drawer => (
                  <>
                    <HeaderWrapper>
                      <HeaderInner>
                        <HeaderColumn className="left">
                          <LogoWrapper>
                            <Link to="/">
                              <LogoSmallWhite />
                            </Link>
                          </LogoWrapper>
                        </HeaderColumn>

                        <HeaderColumn className="center">
                          <SearchBar />
                        </HeaderColumn>

                        <HeaderColumn
                          className={
                            drawer.isOpen ? 'right menu-open' : 'right'
                          }
                        ></HeaderColumn>
                      </HeaderInner>
                    </HeaderWrapper>
                    <MobileMenu data={mobileCategories} />
                  </>
                )}
              </DrawerTrigger>
            </>
          )
        }
      </Above>
    </>
  );
};

export default function Header() {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const handleScroll = () => {
    if (window.pageYOffset >= 175) {
      setSticky(true);
    }
    if (window.pageYOffset < 175) {
      setSticky(false);
    }
  };

  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  const mobileResult = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 3
    }
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to avoid memory leaks
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <CartFlyout />
      <Notifications />
      <Container className={isSticky ? 'scrolled' : 'not-scrolled '} ref={ref}>
        <TopHeader
          mobileCategories={mobileResult.data}
          categories={result.data}
          scrolled={isSticky ? true : false}
        />
      </Container>
    </>
  );
}
