import React from 'react';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Spacer } from '../Spacer';

export const MembersSpacer = ({ loggedIn, children, ...props }) => {
  const auth = useAuth();
  const isLoggedIn = auth?.loggedIn;

  //Generall (not logged in)
  if (!loggedIn?.value && !isLoggedIn) {
    return <Spacer {...props} />;
  }

  //Logged in
  if (loggedIn?.value && isLoggedIn) {
    return <Spacer {...props} />;
  }

  return null;
};
