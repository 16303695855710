import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Above } from '@jetshop/ui/Breakpoints';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import { styled } from 'linaria/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as Bars } from '../../../svg/Bars.svg';
import { ReactComponent as BrannLogo } from '../../../svg/BrannLogo.svg';
import { ReactComponent as Logo } from '../../../svg/Logo.svg';
import { ReactComponent as User } from '../../../svg/User.svg';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { CampaignBar, CampaignBarItem } from '../../ContentEditor/CampaignBar';
import { DynamicContentRenderer } from '../../ContentEditor/DynamicContentRenderer';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import FavouritesFlyout from '../../ProductList/FavouritesFlyout';
import { useSportRoute } from '../../RouteCookie';
import { theme } from '../../Theme';
import DynamicCategoryContent from '../../ui/DynamicCategoryContent';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import FakeChannelSelector from './FakeChannelSelector';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import MobileMenu from './MobileMenu';
import { SearchBar } from './Search/SearchBar';

const MyPagesLink = styled(Link)`
  margin-left: 10px;
  ${theme.below.lg} {
    margin-left: 5px;
  }
  svg {
    width: 20px !important;
    height: 20px !important;
    display: block;
    path {
      fill: black;
    }
  }
`;

const Container = styled('header')`
  background-color: #fff;
  position: relative;
  border-bottom: 1px solid #e0e0e0;

  + main {
    padding-top: 145px;
  }
  ${theme.below.lg} {
    + main {
      padding-top: 86px;
    }
  }
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;

  .top-bar + div + div.left {
    top: 85px;
  }

  &.scrolled {
    .top-bar {
      height: 0;
      overflow: hidden;

      + div + div.left {
        top: 60px;
      }
    }
  }

  a,
  a:visited {
    color: #333333;
  }
`;

export const MenuButton = styled('button')`
  border: 0;
  appearance: none;
  background: none;

  ${theme.below.lg} {
    margin-left: 15px;

    &.open {
      margin-left: 0;
      padding-right: 2px;
    }
  }

  &:focus {
    outline: none;
  }
  svg {
    width: 20px !important;
    height: 20px !important;
    display: block;
    path {
      fill: black;
    }
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

const TopBarWrapper = styled('div')`
  background: ${theme.colors.accent};
  text-align: center;
  &.brann-top-bar {
    background: ${theme.colors.brann};
  }
  a {
    text-decoration: none;
  }
`;
const TopBarInner = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    display: flex;
    justify-content: center;
    align-items: center;
    ${theme.below.lg} {
      width: 100%;
      justify-content: space-evenly;
    }
    li {
      padding: 0 10px;
      height: 35px;
      line-height: 35px;
      font-size: 13px;
      color: white;
      ${theme.below.lg} {
        font-size: 10px;
        height: 25px;
        line-height: 25px;
        padding: 0;
      }

      &:before {
        content: '';
        height: 15px;
        width: 15px;
        background-image: url(/CheckCircle.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        margin-top: -2px;
        ${theme.below.lg} {
          margin-right: 5px;
        }
      }
    }
  }
`;

const HeaderWrapper = styled('div')`
  padding: 0;
  ${theme.below.lg} {
    padding: 10px 0;
  }
`;
const HeaderInner = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  max-width: 1288px;
  margin: auto;
  ${theme.below.lg} {
    padding: 0 10px;
  }
`;
const HeaderColumn = styled('div')`
  flex: 1;
  display: flex;
  align-items: center;

  ${theme.below.lg} {
    flex: none;
  }

  &.left {
    justify-content: flex-start;
    text-align: left;
  }
  &.center {
    justify-content: center;
    flex: auto;
    text-align: center;
    flex-wrap: wrap;
    padding-top: 20px;
    max-width: 100%;
    ${theme.below.lg} {
      padding: 0;
      flex: 1;
    }
  }
  &.right {
    justify-content: flex-end;
    text-align: right;
    &.menu-open {
      display: none;
    }
  }
`;
const LogoWrapper = styled('div')`
  ${theme.below.lg} {
    svg {
      width: 50px;
      height: 40px;
    }
  }

  a {
    display: block;
  }
  img {
    max-width: 100%;
  }
`;

function TopBar() {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <>
      <TopBarWrapper
        className={
          'top-bar' + (selectedChannel.id === 7 ? '  brann-top-bar' : '')
        }
      >
        <TopBarInner>
          <Above breakpoint="lg">
            {matches =>
              matches ? (
                <>
                  <div>
                    <DynamicCategoryContent id={163} />
                  </div>
                  {selectedChannel.id !== 7 && <FakeChannelSelector />}
                </>
              ) : (
                <DynamicCategoryContent id={164} />
              )
            }
          </Above>
        </TopBarInner>
      </TopBarWrapper>
    </>
  );
}

const TopHeader = ({ mobileCategories, categories }) => {
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);
  const { startPath } = useSportRoute();

  return (
    <>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              <HeaderWrapper>
                <HeaderInner>
                  <HeaderColumn className="left">
                    <LogoWrapper>
                      <Link to={startPath}>
                        {selectedChannel.id === 7 ? <BrannLogo /> : <Logo />}
                      </Link>
                    </LogoWrapper>
                  </HeaderColumn>

                  <HeaderColumn className="center">
                    <SearchBar />
                    <CategoryMenu data={categories} />
                  </HeaderColumn>

                  <HeaderColumn className="right">
                    <MyPagesLink to={routes.myPages.path}>
                      <User />
                    </MyPagesLink>
                    <FavouriteCount />
                    <CartButton />
                  </HeaderColumn>
                </HeaderInner>
              </HeaderWrapper>
            </>
          ) : (
            <>
              <DrawerTrigger preventOverflow={true} id="menu-drawer">
                {drawer => (
                  <>
                    <HeaderWrapper>
                      <HeaderInner>
                        <HeaderColumn className="left">
                          <LogoWrapper>
                            <Link to={startPath}>
                              {selectedChannel.id === 7 ? (
                                <BrannLogo />
                              ) : (
                                <Logo />
                              )}
                            </Link>
                          </LogoWrapper>
                        </HeaderColumn>

                        <HeaderColumn className="center">
                          <SearchBar />
                        </HeaderColumn>

                        <HeaderColumn>
                          {!drawer.isOpen && (
                            <>
                              <MyPagesLink to={routes.myPages.path}>
                                <User />
                              </MyPagesLink>

                              <FavouriteCount />
                              <CartButton />
                            </>
                          )}

                          <MenuButton
                            onClick={
                              drawer.isOpen
                                ? drawer.hideTarget
                                : drawer.showTarget
                            }
                            className={drawer.isOpen ? 'open' : 'closed'}
                          >
                            <IconContainer>
                              {drawer.isOpen ? <Cross /> : <Bars />}
                            </IconContainer>
                          </MenuButton>
                        </HeaderColumn>
                      </HeaderInner>
                    </HeaderWrapper>
                    <MobileMenu data={mobileCategories} />
                  </>
                )}
              </DrawerTrigger>
            </>
          )
        }
      </Above>
    </>
  );
};

const HeaderCampaignBar = () => {
  const location = useLocation();
  let categoryId;
  switch (true) {
    case location?.pathname?.startsWith('/fotball'):
      categoryId = 1592;
      break;
    case location?.pathname?.startsWith('/lop'):
      categoryId = 1593;
      break;
    case location?.pathname?.startsWith('/hockey'):
      categoryId = 1594;
      break;
    case location?.pathname?.startsWith('/klubbservice'):
      categoryId = 1595;
      break;
    case location?.pathname?.startsWith('/andre-idretter'):
      categoryId = 1596;
      break;
    default:
      categoryId = 1591;
      break;
  }

  const campaignComponents = {
    CAMPAIGNBAR: CampaignBar,
    CAMPAIGNBARITEM: CampaignBarItem
  };

  return (
    <DynamicContentRenderer
      categoryId={categoryId}
      rendererComponents={campaignComponents}
    />
  );
};

export default function Header() {
  const { selectedChannel } = useContext(ChannelContext);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);
  const location = useLocation();

  const menuMapper = {
    start: 0,
    fotball: 950,
    lop: 952,
    klubbservice: 953,
    'andre-idretter': 954,
    hockey: 951,
    brann: 955
  };

  const handleScroll = () => {
    if (window.pageYOffset >= 175) {
      setSticky(true);
    }
    if (window.pageYOffset < 175) {
      setSticky(false);
    }
  };

  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1,
      root:
        selectedChannel.id === 7
          ? 955
          : menuMapper[location.pathname.split('/')[1]]
    }
  });
  const mobileResult = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 5,
      root:
        selectedChannel.id === 7
          ? 955
          : menuMapper[location.pathname.split('/')[1]]
    }
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  return (
    <>
      {/* <ChannelBanner /> */}
      <CartFlyout />
      <FavouritesFlyout />
      <Container className={isSticky ? 'scrolled' : 'not-scrolled '} ref={ref}>
        <TopBar />
        <TopHeader
          mobileCategories={mobileResult.data}
          categories={result.data}
          scrolled={isSticky ? true : false}
        />
        <HeaderCampaignBar />
      </Container>
    </>
  );
}
