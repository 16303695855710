import React from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { css, cx } from 'linaria';
import { theme } from '../../Theme';

const OuterWrapper = styled('div')`
  width: 100%;
  max-width: 1288px;
  padding: 0 20px;
  margin: auto;
  text-align: center;
  ${theme.below.lg} {
  }
`;

const Inner = styled('div')`
  display: flex;
  align-items: center;
  border-left: 1px solid #e0e0e0;
  ${theme.below.lg} {
    flex-wrap: wrap;
    border: 0;
    justify-content: center;
  }
`;

const CallToAction = styled(Link)``;

export const callToActionStyle = css`
  width: 100%;
  padding: 15px 0;
  border: 1px solid #e0e0e0;
  color: ${theme.colors.black};
  font-size: 0.8rem;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  transition: all, 0.2s ease;
  padding: 0 3px;
  height: 50px;
  line-height: 50px;

  img {
    max-height: 30px;
    max-width: 30px;
    margin-right: 3px;
    ${theme.below.lg} {
      max-height: 15px;
      max-width: 15px;
    }
  }

  &:hover {
    background: #eaeaea;
  }
  ${theme.below.lg} {
    width: 48%;
    border-bottom: 0;
    font-size: 0.65rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    line-height: 1.3;

    border: 0;
    outline: 1px solid #e0e0e0;
    margin-left: 1px;
    margin-bottom: 1px;
    padding: 0 5px;

    height: 35px;
    min-height: 35px;
  }
`;

const CallToActionLink = styled('a')`
  width: 100%;
  padding: 15px 0;
  border: 1px solid #e0e0e0;
  border-left: 0;
  color: ${theme.colors.black};
  font-size: 0.8rem;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  transition: all, 0.2s ease;
  padding: 0 3px;
  height: 50px;
  line-height: 50px;

  img {
    max-height: 30px;
    max-width: 30px;
    margin-right: 3px;
    ${theme.below.lg} {
      max-height: 15px;
      max-width: 15px;
    }
  }

  &:hover {
    background: #eaeaea;
  }
  ${theme.below.lg} {
    width: 48%;
    border-bottom: 0;
    font-size: 0.65rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45px;
    line-height: 1.3;

    border: 0;
    outline: 1px solid #e0e0e0;
    margin-left: 1px;
    margin-bottom: 1px;
    padding: 0 5px;

    height: 35px;
    min-height: 35px;
  }
`;

export const LinkItem = ({ buttonText, buttonLink, buttonIcon, external }) => (
  <>
    {external ? (
      <CallToActionLink
        className={'link-item'}
        href={buttonLink}
        target="_blank"
      >
        {buttonIcon ? <img src={buttonIcon} alt={buttonText} /> : null}{' '}
        {buttonText}
      </CallToActionLink>
    ) : (
      <CallToAction
        className={cx(callToActionStyle, 'link-item')}
        to={
          external?.length > 0
            ? {
                pathname: buttonLink
              }
            : buttonLink
        }
        target={external?.length > 0 ? '_blank' : '_self'}
      >
        {buttonIcon ? <img src={buttonIcon} alt={buttonText} /> : null}{' '}
        {buttonText}
      </CallToAction>
    )}
  </>
);

const StartCategories = ({ links }) => {
  return (
    <React.Fragment>
      {links ? (
        <OuterWrapper>
          <Inner>
            {links.map((Link, index) => (
              <LinkItem key={index} {...Link} />
            ))}
          </Inner>
        </OuterWrapper>
      ) : null}
    </React.Fragment>
  );
};

export default StartCategories;
