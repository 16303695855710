import { BoxRow, ImageBoxLink } from './BoxRow';
import { CampaignBannerRow, CampaignBannerRowItem } from './CampaignBannerRow';
import { Spacer } from './Spacer';
import { ContentBoxLink } from './ContentBoxLink';
import { ProductRow, ProductRowItem } from './ProductRow';
import { TopBlock, TopBlockItem } from './TopBlock';
import { CategoryRow, CategoryRowItem } from './CategoryRow';
import { DynamicYoutubeBox } from './DynamicYoutubeBox';
import { HtmlRow } from './HtmlRow';
import { HtmlBox } from './HtmlBox';
import { CampaignBar, CampaignBarItem } from './CampaignBar';
import { IFrame } from './IFrame';
import { AnchorLink, AnchorLinks } from './AnchorLinks';
import {
  ImageGalleryRow,
  ImageGalleryRowItem
} from './ImageGalleryRow/ImageGalleryRow';
import { ImageGallerySlidePresenter } from './ImageGalleryRow/ImageGallerySlidePresenter';
import { ImageGallerySlideButton } from './ImageGalleryRow/ImageGallerySlideButton';
import { ImageGalleryPageControls } from './ImageGalleryRow/ImageGalleryPageControls';

//Membersclub components
import { MembersHero } from './MembersClubComponents/MembersHero';
import { MembersHtmlRow } from './MembersClubComponents/MembersHtmlRow';
import { MembersBoxRow } from './MembersClubComponents/MembersBoxRow';
import { MembersSpacer } from './MembersClubComponents/MembersSpacer';

export const contentEditorBaseComponents = {
  SPACER: Spacer,
  BOXROW: BoxRow,
  IMAGEBOXLINK: ImageBoxLink,
  CONTENTBOXLINK: ContentBoxLink,
  CAMPAIGNBANNERROW: CampaignBannerRow,
  CAMPAIGNBANNERITEM: CampaignBannerRowItem,
  PRODUCTROW: ProductRow, //Might be an issue bc same name as a default startpage component
  PRODUCTROWITEM: ProductRowItem,
  CONTENTTOPBLOCK: TopBlock,
  CONTENTTOPBLOCKITEM: TopBlockItem,
  CATEGORYROW: CategoryRow,
  CATEGORYROWITEM: CategoryRowItem,
  YOUTUBEBOX: DynamicYoutubeBox,
  HTMLROW: HtmlRow,
  HTMLBOX: HtmlBox,
  CAMPAIGNBAR: CampaignBar,
  CAMPAIGNBARITEM: CampaignBarItem,
  IFRAME: IFrame,
  ANCHORLINKS: AnchorLinks,
  ANCHORLINK: AnchorLink,
  IMAGEGALLERYROW: ImageGalleryRow,
  IMAGEGALLERYROWITEM: ImageGalleryRowItem,
  IMAGEGALLERYROWCURRENTSLIDESHOWER: ImageGallerySlidePresenter,
  IMAGEGALLERYROWSLIDERBUTTONS: ImageGallerySlideButton,
  IMAGEGALLERYROWPAGECONTROLS: ImageGalleryPageControls,
  //Membersclub components
  MEMBERSHERO: MembersHero,
  MEMBERSHTMLROW: MembersHtmlRow,
  MEMBERSBOXROW: MembersBoxRow,
  MEMBERSSPACER: MembersSpacer
};
