import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';

const StyledHeading = styled('h2')`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  width: 100%;
  max-width: 90rem;
  margin: 50px auto 20px auto;
  ${theme.below.lg} {
    font-size: 22px;
    margin: 30px auto 20px auto;
  }
`;

const Title = ({ text }) => <StyledHeading>{text}</StyledHeading>;

export default Title;
