import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { callToActionStyle } from '../StartPage/Content/Categories';
import { CategoryRowWrapper, LinksContainer } from './CategoryRow';

export const AnchorLinks = ({
  backgroundColor,
  foregroundColor,
  hoverColor,
  radius,
  children
}) => {
  const radiusIsInuse = parseInt(radius?.value) > 0;
  return (
    <CategoryRowWrapper>
      <LinksContainer
        hovercolor={hoverColor?.value}
        backgroundcolor={backgroundColor?.value}
        foregroundcolor={foregroundColor?.value}
        radius={radius?.value}
        className={cx('links-container', radiusIsInuse && 'no-border')}
        parts={children?.length}
      >
        {children}
      </LinksContainer>
    </CategoryRowWrapper>
  );
};

const AnchorLinkWrapper = styled('div')`
  cursor: pointer;
`;

export const AnchorLink = ({ text, anchor }) => {
  const handleAnchorClick = () => {
    typeof window !== 'undefined' &&
      document
        ?.getElementById(anchor?.value)
        ?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  };

  return (
    <AnchorLinkWrapper
      className={cx('anchor-link', callToActionStyle)}
      onClick={handleAnchorClick}
    >
      {text?.value}
    </AnchorLinkWrapper>
  );
};
