import React, { createContext, useState } from 'react';
import { theme } from '../Theme';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { ReactComponent as Carrot } from '../../svg/Caret.svg';
import { Dot, Dots } from '../StartPage/Content/Row';

const SliderWrapper = styled('div')`
  width: 100%;
  .slick-slider .slick-arrow {
    top: ${props => props.arrowverticalposition};
  }

  .slick-list {
    padding-top: 1px;
    padding-left: 1px;
  }
`;

const Arrow = styled('button')`
  border: 0;
  height: 40px;
  width: 40px;
  background: #ffffffdd;
  border: 1px solid ${theme.colors.primary};
  position: absolute;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  ${theme.below.md} {
    height: 40px;
    width: 40px;
  }

  &:hover {
    background: #ffffff;
  }

  &.slick-next {
    right: -5px;
    left: auto;
    z-index: 1;
    svg {
      transform: rotate(270deg);

      ${theme.below.lg} {
        margin-right: 2px;
      }
    }
    ${theme.below.lg} {
      right: 15px;
    }
  }
  &.slick-prev {
    left: -5px;
    right: auto;
    z-index: 1;
    svg {
      transform: rotate(90deg);

      ${theme.below.lg} {
        margin-right: 2px;
      }
    }
    ${theme.below.lg} {
      left: 15px;
    }
  }
  &:focus {
    outline: none;
  }

  svg {
    color: ${theme.colors.primary};
    width: 16px;
    height: 12px;
  }
`;

export const SliderContext = createContext();

const CustomArrow = props => {
  const { className, onClick } = props;
  return (
    <Arrow className={className} onClick={onClick}>
      <Carrot />
    </Arrow>
  );
};

export const SharedSlider = ({
  desktopSlides,
  mobileSlides,
  arrowTopPosition,
  children
}) => {
  const [dragging, setDragging] = useState(false);
  const desktopSlidesInt = parseInt(desktopSlides);
  const mobileSlidesInt = parseInt(mobileSlides);
  const desktopSlideQuantity = parseInt(
    children?.length > desktopSlidesInt ? desktopSlidesInt : children?.length
  );
  const mobileSlideQuantity =
    children?.length > mobileSlidesInt ? mobileSlidesInt : children?.length;

  const arrowVerticalPosition = arrowTopPosition ?? '38%';

  const desktopSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    slidesToShow: desktopSlideQuantity,
    slidesToScroll: 1,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
    adaptiveHeight: true,
    appendDots: dots => <Dots>{dots}</Dots>,
    customPaging: i => <Dot />,
    swipeToSlide: true
  };

  const mobileSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    slidesToShow: mobileSlideQuantity,
    slidesToScroll: 1,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
    appendDots: dots => <Dots>{dots}</Dots>,
    customPaging: i => <Dot />,
    swipeToSlide: true
  };

  return (
    <SliderWrapper
      className={'shared-slider-wrapper'}
      arrowverticalposition={arrowVerticalPosition}
    >
      <SliderContext.Provider value={{ dragging: dragging }}>
        <Above breakpoint="lg">
          {matches =>
            matches ? (
              <Slider {...desktopSettings}>{children}</Slider>
            ) : (
              <Slider {...mobileSettings}>{children}</Slider>
            )
          }
        </Above>
      </SliderContext.Provider>
    </SliderWrapper>
  );
};
