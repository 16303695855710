import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import { LoadableProductPage, LoadableContentPage } from './components/Shop';
import {
  LoadableStandardCategoryPage,
  LoadableWindowedCategoryPage
} from './components/CategoryPage/CategoryPage';
import { theme } from './components/Theme';

const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'torshov',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
    persistedQueries: [
      {
        query: homeCategoriesQuery,
        variables: { levels: 1 }
      }
    ]
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
            useGrouping: false
          },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          NOK: {
            style: 'currency',
            currency: 'NOK',
            minimumFractionDigits: 0,
            useGrouping: false
          },
          NKR: {
            style: 'currency',
            currency: 'NOK',
            minimumFractionDigits: 0,
            useGrouping: false
          },
          AUD: { style: 'currency', currency: 'USD' },
          DKK: {
            style: 'currency',
            currency: 'DKK',
            minimumFractionDigits: 0,
            useGrouping: false
          },
          DKR: {
            style: 'currency',
            currency: 'DKK',
            minimumFractionDigits: 0,
            useGrouping: false
          }
        }
      }
    }
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  openGraph: {
    disableDefaultProductData: false
  },
  trackers: [],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [LoadableProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [LoadableContentPage]
    }
  },
  startPageMapperIndex: {
    '/start': 1,
    '/fotball': 2,
    '/lop': 3,
    '/klubbservice': 4,
    '/andre-idretter': 5,
    '/hockey': 6,
    '/brann': 7
  },
  availableStoresForClickCollect: [20, 21, 23, 26, 27, 28, 31, 32, 33, 34, 35],
  NORCE_INTERNAL_STORE_IDS_USING_FRONTSYSTEMS_API: [
    20,
    21,
    23,
    26,
    27,
    28,
    32,
    33,
    35
  ]
};
export default config;
