import React from 'react';
import { styled } from 'linaria/react';

const SaleBadgeWrapper = styled('div')`
  &.product-page-badge {
    position: absolute;
    top: 40px;
    right: 0;
    display: block;
    font-size: 10px;
    color: white;
    z-index: 1;
    pointer-events: none;
    background: #d80e0e;
    height: 20px;
    line-height: 20px;
    min-width: 35px;
    text-align: center;
    font-weight: bold;
  }

  &.product-card-badge {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: block;
    font-size: 10px;
    color: white;
    z-index: 1;
    pointer-events: none;
    background: #d80e0e;
    height: 20px;
    line-height: 20px;
    min-width: 35px;
    text-align: center;
    font-weight: bold;
  }
`;

const SaleBadge = ({ product, productPage }) => {
  function checkSaleBadge(categories) {
    var run = true;
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      if (
        category.primaryRoute.path.indexOf('/klubbservice/klubbkolleksjoner') >
        -1
      ) {
        run = false;
      }
    }
    return run;
  }

  if (product?.categories?.length > 0 && !checkSaleBadge(product.categories)) {
    return null;
  }
  return (
    <SaleBadgeWrapper
      className={productPage ? 'product-page-badge' : 'product-card-badge'}
    >
      -
      {Math.round(
        ((product?.previousPrice?.incVat - product?.price?.incVat) /
          product?.previousPrice?.incVat) *
          100
      )}{' '}
      %
    </SaleBadgeWrapper>
  );
};

export default SaleBadge;
