import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../Theme';
import { LinkItem } from '../StartPage/Content/Categories';
import { boxStyling } from './BoxRow';
import { createAnchorProps } from '../../utils/HelpFunctions';

export const CategoryRowWrapper = styled('div')`
  max-width: 80rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;

  h2 {
    margin-bottom: 1rem;
  }

  &.fullwidth {
    max-width: 100%;
  }
`;

export const LinksContainer = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: transparent;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  grid-row-gap: 5px;
  grid-column-gap: ${props => (props.radius > 0 ? '5px' : '0')};

  ${theme.above.lg} {
    justify-content: center;
  }
  &.horizontal {
    overflow-x: auto !important;
    flex-wrap: nowrap;
  }

  &.no-border {
    border: none;
    > a,
    div {
      outline: none !important;
    }
  }

  a,
  div {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    background: ${props => props.backgroundcolor};
    color: ${props => props.foregroundcolor};
    border-radius: ${props => props.radius}px;
    &:hover {
      background: ${props => props.hovercolor};
    }
    ${theme.below.lg} {
      display: block;
      line-height: 35px;
      flex: none;
      width: calc(50% - 5px);
      border-radius: ${props => (props.radius / 50) * 35}px;
    }
  }
`;

export const CategoryRow = ({
  title,
  fullwidth,
  hoverColor,
  backgroundColor,
  foregroundColor,
  radius,
  anchorTag,
  children
}) => {
  const anchorProps = createAnchorProps(anchorTag?.value);
  const radiusIsInuse = parseInt(radius?.value) > 0;
  return (
    <CategoryRowWrapper
      {...anchorProps}
      className={cx(!!fullwidth?.value && 'fullwidth', boxStyling)}
    >
      {title?.value && <h2>{title.value}</h2>}
      <LinksContainer
        hovercolor={hoverColor?.value}
        backgroundcolor={backgroundColor?.value}
        foregroundcolor={foregroundColor?.value}
        radius={radius?.value}
        className={cx('links-container', radiusIsInuse && 'no-border')}
        parts={children?.length}
      >
        {children}
      </LinksContainer>
    </CategoryRowWrapper>
  );
};

export const CategoryRowItem = ({ text, link, icon }) => {
  return (
    <LinkItem
      buttonText={text?.value}
      buttonLink={link?.value}
      buttonIcon={icon?.value}
      external={null}
    />
  );
};
