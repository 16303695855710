import { theme } from '../Theme';
import { useIntl } from '@jetshop/intl';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';
import React from 'react';
import { css, cx } from 'linaria';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import { Below, Above } from '@jetshop/ui/Breakpoints';
import StockOrb from './StockStatus/StockOrb';
import { InputCheck } from '../ui/InputCheck';

export function VariantSelector({
  product,
  variantHandler,
  showValidation,
  hideCheck,
  disabledClickCollect
}) {
  return (
    <div className={sharedStyles}>
      {product.variants.options.map(option => {
        let showValidationMessage = false;
        if (
          showValidation &&
          variantHandler.getMissingOptions()?.includes(option)
        ) {
          showValidationMessage = true;
        }

        return (
          <DropdownVariantSelect
            key={option.name}
            option={option}
            variantHandler={variantHandler}
            showValidationMessage={showValidationMessage}
            hideCheck={hideCheck}
            disabledClickCollect={disabledClickCollect}
          />
        );
      })}
    </div>
  );
}

const sharedStyles = css`
  label {
    display: flex;
    padding-bottom: 0.25em;
    margin-top: 1em;
    font-size: 0.775em;
    align-items: center;
  }
  .invalid {
    label {
      color: #eb5757;
    }
  }
  .missingVariant {
    margin-right: 0.25em;
    height: 10px;
    width: 10px;
  }
`;

const clickAndCollectStatus = 'Tilgjengelig via Klikk og Hent';
const checkWarehouseAvailability = warehouses =>
  warehouses?.some(store => store.stockLevel > 0) ?? false;

function DropdownVariantSelect({
  option,
  variantHandler,
  showValidationMessage,
  hideCheck,
  disabledClickCollect
}) {
  const {
    getSelectedValue,
    validateSelection,
    selectValue,
    getVariantForSelection
  } = variantHandler;
  const t = useIntl();

  const selectedValue = getSelectedValue(option);
  const variant = getVariantForSelection(selectedValue, option);

  let showInstore =
    selectedValue &&
    !variant?.stockStatus?.buyable &&
    checkWarehouseAvailability(variant?.warehouseStock);

  return (
    <div className={cx(dropdownStyles, showValidationMessage && 'invalid')}>
      <label className="variant-label" htmlFor={`option-${option.name}`}>
        <Below breakpoint="md">{matches => matches && option.name}</Below>
        {showValidationMessage && (
          <>
            <StockOrb className="missingVariant" />
            <Above breakpoint="md">
              {matches =>
                matches && t('Select {option}', { option: option.name })
              }
            </Above>
          </>
        )}
      </label>
      <DropdownMenu>
        <DropdownMenuButton id={`option-${option.name}`}>
          {selectedValue && variant ? (
            <>
              <Above breakpoint="md">
                {matches =>
                  matches ? (
                    <span>{option.name + ': ' + selectedValue}</span>
                  ) : (
                    selectedValue
                  )
                }
              </Above>
              <span>
                <span
                  className={
                    'stock-status ' + (!showInstore ? 'in-stock' : 'in-store')
                  }
                >
                  {showInstore
                    ? t(clickAndCollectStatus)
                    : variant?.stockStatus?.text}
                </span>
                {!hideCheck ? <InputCheck /> : <Caret />}
              </span>
            </>
          ) : (
            <>
              {t.rich('Select {option}', { option: option.name })} <Caret />
            </>
          )}
        </DropdownMenuButton>
        <DropdownMenuItems>
          {option.values.map((value, index) => {
            const validation = validateSelection(value, option);
            const variant = getVariantForSelection(value, option);

            // We're not querying for warehouse stock on related products therefor we need to null check it.
            const hasStockLevel = checkWarehouseAvailability(
              variant?.warehouseStock
            );

            showInstore = !variant.stockStatus.buyable && hasStockLevel;

            if (!hasStockLevel && !variant.stockStatus.buyable) {
              // DropdownMenuItems requires at least one React Element.
              // Render this dummy if the products is missing from both regular stock and warehouse stock
              return (
                <>
                  {disabledClickCollect === false ? (
                    <DropdownMenuItem key={index} style={{ display: 'none' }} />
                  ) : (
                    variant?.stockStatus?.buyable && (
                      <DropdownMenuItem
                        key={index}
                        style={{ display: 'none' }}
                      />
                    )
                  )}
                </>
              );
            }

            return (
              <>
                {disabledClickCollect === false ? (
                  <DropdownMenuItem
                    data-testid={value + option.name}
                    key={value + option.name}
                    disabled={validation === 'invalid'}
                    onSelect={({ setIsOpen }) => {
                      selectValue(value, option);
                      setIsOpen(false);
                    }}
                    style={{ opacity: validation === 'invalid' ? 0.5 : 1 }}
                    className={validation === 'invalid' ? 'disabled' : null}
                  >
                    <span>{value}</span>
                    <span
                      className={
                        'stock-status ' +
                        (!showInstore ? 'in-stock' : 'in-store')
                      }
                    >
                      {showInstore
                        ? t(clickAndCollectStatus)
                        : variant?.stockStatus?.text}
                    </span>
                  </DropdownMenuItem>
                ) : (
                  variant?.stockStatus?.buyable && (
                    <DropdownMenuItem
                      data-testid={value + option.name}
                      key={value + option.name}
                      disabled={validation === 'invalid'}
                      onSelect={({ setIsOpen }) => {
                        selectValue(value, option);
                        setIsOpen(false);
                      }}
                      style={{ opacity: validation === 'invalid' ? 0.5 : 1 }}
                      className={validation === 'invalid' ? 'disabled' : null}
                    >
                      <span>{value}</span>
                      <span
                        className={
                          'stock-status ' +
                          (!showInstore ? 'in-stock' : 'in-store')
                        }
                      >
                        {showInstore
                          ? t(clickAndCollectStatus)
                          : variant?.stockStatus?.text}
                      </span>
                    </DropdownMenuItem>
                  )
                )}
              </>
            );
          })}
        </DropdownMenuItems>
      </DropdownMenu>
    </div>
  );
}

export const dropdownStyles = css`
  margin: 0 0 10px 0;
  ${theme.below.lg} {
    margin: 0;
  }
  button,
  div {
    border-radius: 0 !important;
    &:focus {
      border-radius: 0 !important;
    }
  }

  span.stock-status {
    &.in-stock {
      color: #04b000;
    }
    &.out-of-stock {
      color: #e50000;
    }
    &.few-left {
      color: #ff9900;
    }
    &.in-store {
      color: #e2a300;
      ${theme.below.sm} {
        font-size: 11px;
      }
      + span {
        border: 1px solid #e2a300;
        use {
          fill: #e2a300;
        }
      }
    }
  }

  [data-flight-dropdown-button] {
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #333333;
    border-radius: 0 !important;
    height: 35px;
    line-height: 35px;
    text-align: left;
    text-transform: uppercase;
    justify-content: space-between;
    font-size: 11px;
    font-weight: normal;
    ${theme.below.lg} {
      margin-bottom: 10px;
    }
    ${theme.below.sm} {
      height: auto;
      line-height: unset;
    }

    span.stock-status {
      padding: 0 20px;
      &.in-stock {
        color: #04b000;
      }
      &.out-of-stock {
        color: #e50000;
      }
      &.few-left {
        color: #ff9900;
      }
      ${theme.below.lg} {
        padding: 0 20px;
      }
    }

    > svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }

    &[aria-expanded='false'] {
      &[aria-haspopup='menu'] {
        border-radius: 0 !important;
      }
    }

    &:focus,
    &[aria-expanded='true'] {
      outline: none;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    + ul[data-flight-dropdown-items] {
      border-color: #333333;
      max-height: 400px;
      overflow-y: auto;

      > li[data-flight-dropdown-item] {
        border: 0;
        justify-content: space-between;
        font-size: 13px;

        &:nth-child(odd) {
          background: #f7f7f7;
        }

        &.disabled {
          color: red;
        }
        &:hover,
        &:focus {
          color: ${theme.colors.accent};
          outline: none;
        }
      }
    }
  }
  [data-flight-dropdown-open='true'] {
    [data-flight-dropdown-button] {
      border-bottom-color: transparent;
    }
  }
  [data-flight-dropdown-items] {
    width: 100%;
    border: 1px solid #dedede;
    margin-top: -1px;
  }
  [data-flight-dropdown-item] {
    display: flex;
    align-items: center;
    :last-child {
    }
  }
  &.invalid [data-flight-dropdown-button] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  svg {
    margin-left: auto;
  }
`;
