import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Above } from '@jetshop/ui/Breakpoints';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import { ImageGallerySlidePresenter } from './ImageGallerySlidePresenter';
import { ImageGallerySlideButton } from './ImageGallerySlideButton';
import {
  ImageGalleryPageControlIndicator,
  ImageGalleryPageControls
} from './ImageGalleryPageControls';

const SliderWrapper = styled('div')`
  width: 100%;
  position: relative;

  &.hide-slide-buttons {
    .slick-next,
    .slick-prev {
      display: none !important;
    }
  }
`;

export const ImageGalleryRowSlider = ({
  desktopSlides,
  mobileSlides,
  currentSlideChild,
  customSlideButtonChild,
  pageControlsChild,
  children
}) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const desktopSlidesInt = parseInt(desktopSlides);
  const mobileSlidesInt = parseInt(mobileSlides);
  const desktopSlideQuantity = parseInt(
    children?.length > desktopSlidesInt ? desktopSlidesInt : children?.length
  );
  const mobileSlideQuantity =
    children?.length > mobileSlidesInt ? mobileSlidesInt : children?.length;

  const updateCurrentIndex = (current, next) => {
    setCurrentIndex(next + 1);
  };

  const desktopSettings = {
    dots: !!pageControlsChild,
    infinite: true,
    speed: 500,
    nextArrow: customSlideButtonChild ? (
      <ImageGallerySlideButton {...customSlideButtonChild?.props} />
    ) : null,
    prevArrow: customSlideButtonChild ? (
      <ImageGallerySlideButton {...customSlideButtonChild?.props} />
    ) : null,
    slidesToShow: desktopSlideQuantity,
    slidesToScroll: 1,
    adaptiveHeight: true,
    appendDots: !!pageControlsChild
      ? dots => (
          <ImageGalleryPageControls
            indicators={dots}
            {...pageControlsChild?.props}
          />
        )
      : null,
    customPaging: !!pageControlsChild
      ? i => <ImageGalleryPageControlIndicator />
      : null,
    swipeToSlide: true,
    beforeChange: (current, next) => updateCurrentIndex(current, next),
    centerMode: true
  };

  const mobileSettings = {
    dots: !!pageControlsChild,
    infinite: true,
    speed: 500,
    nextArrow: customSlideButtonChild ? (
      <ImageGallerySlideButton {...customSlideButtonChild?.props} />
    ) : null,
    prevArrow: customSlideButtonChild ? (
      <ImageGallerySlideButton {...customSlideButtonChild?.props} />
    ) : null,
    slidesToShow: mobileSlideQuantity,
    slidesToScroll: 1,
    adaptiveHeight: true,
    appendDots: !!pageControlsChild
      ? dots => (
          <ImageGalleryPageControls
            indicators={dots}
            {...pageControlsChild?.props}
          />
        )
      : null,
    customPaging: !!pageControlsChild
      ? i => <ImageGalleryPageControlIndicator />
      : null,
    swipeToSlide: true,
    beforeChange: (current, next) => updateCurrentIndex(current, next)
  };

  return (
    <SliderWrapper
      className={cx(
        'image-slider-wrapper',
        !customSlideButtonChild && 'hide-slide-buttons'
      )}
    >
      {currentSlideChild && (
        <ImageGallerySlidePresenter
          current={currentIndex}
          total={children?.length}
          {...currentSlideChild?.props}
        />
      )}

      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <Slider {...desktopSettings}>{children}</Slider>
          ) : (
            <Slider {...mobileSettings}>{children}</Slider>
          )
        }
      </Above>
    </SliderWrapper>
  );
};
