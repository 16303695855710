import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { ReactComponent as Check } from '../../svg/Check.svg';

const InputCheckDiv = styled('span')`
  height: 18px;
  width: 18px;
  border: 1px solid ${theme.colors.green};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 100%;

  svg {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    use {
      fill: ${theme.colors.green};
    }
  }
`;

export const InputCheck = () => (
  <InputCheckDiv className="input-check">
    <Check />
  </InputCheckDiv>
);
