import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import { Link } from 'react-router-dom';

const Wrapper = styled('div')`
  display: block;
  width: 100%;
  max-width: ${props => (props.fullWidth ? '100%' : '1288px')};
  margin: ${props => (props.whitespace ? '100px auto' : '0 auto')};
  padding: ${props => (props.fullWidth ? '0' : '0 20px')};
  padding: 20px;

  ${theme.below.lg} {
    display: block;
    flex: none;
    margin-bottom: 0;
    margin: ${props =>
      !props.whitespace && !props.textbelow
        ? '0 auto'
        : props.whitespace
        ? '50px auto'
        : '0 auto 10px auto'};
  }
`;
const Content = styled('div')`
  p {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
`;

const ButtonsRow = styled('div')`
  &.multiple {
    margin: 10px -5px 0 -5px;
    > span {
      margin: 20px 5px 0 5px;
    }
  }
`;

const FakeButton = styled('span')`
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 30px;
  background: ${props => (props.background ? props.background : 'black')};
  font-size: 11px;
  font-weight: 700;
  margin-top: 20px;
  transition: all, 0.2s ease;
  color: ${props => (props.color ? props.color : 'white')};

  &:hover {
    background: ${props =>
      props.backgroundhover ? props.backgroundhover : 'black'};
    color: ${props => (props.colorhover ? props.colorhover : 'white')};
  }
`;

const LinkButton = styled(Link)`
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 30px;
  background: ${props => (props.background ? props.background : 'black')};
  font-size: 11px;
  font-weight: 700;
  margin-top: 20px;
  transition: all, 0.2s ease;
  color: ${props => (props.color ? props.color : 'white')};

  &:hover {
    background: ${props =>
      props.backgroundhover ? props.backgroundhover : 'black'};
    color: ${props => (props.colorhover ? props.colorhover : 'white')};
  }
`;

const HtmlBlock = ({ content, buttons }) => (
  <Wrapper>
    <Content
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
    {buttons && (
      <ButtonsRow className={buttons.length > 1 ? 'multiple' : ''}>
        {buttons.map((button, index) => (
          <React.Fragment key={index}>
            {button.link ? (
              <LinkButton
                to={button.link}
                key={index}
                background={button.background ? button.background : 'white'}
                color={button.color ? button.color : 'black'}
                backgroundhover={
                  button.backgroundhover ? button.backgroundhover : 'black'
                }
                colorhover={button.colorhover ? button.colorhover : 'white'}
              >
                {button.text}
              </LinkButton>
            ) : (
              <FakeButton
                key={index}
                background={button.background ? button.background : 'white'}
                color={button.color ? button.color : 'black'}
                backgroundhover={
                  button.backgroundhover ? button.backgroundhover : 'black'
                }
                colorhover={button.colorhover ? button.colorhover : 'white'}
              >
                {button.text}
              </FakeButton>
            )}
          </React.Fragment>
        ))}
      </ButtonsRow>
    )}
  </Wrapper>
);

export default HtmlBlock;
