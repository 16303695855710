import React from 'react';
import ProductLink from '@jetshop/ui/ProductLink';
import CategoryLink from '@jetshop/ui/CategoryLink';

const SuggestedTerm = props => {
  const { term = '', className = '', onClick = () => {} } = props;

  if (isProduct(props)) {
    const { item } = props;
    return (
      <li
        className={className}
        onClick={onClick}
        data-flight-search-autocomplete-result="product"
      >
        {item.primaryRoute ? (
          <ProductLink product={item}>
            {getHighlightedText(item.name, term)}
          </ProductLink>
        ) : (
          item.name
        )}
      </li>
    );
  } else {
    const { item } = props;
    const displayText = parentsToString(item.primaryRoute.parents) + item.name;
    return (
      <li
        className={className}
        onClick={onClick}
        data-flight-search-autocomplete-result="category"
      >
        {item.primaryRoute ? (
          <CategoryLink category={item}>
            {getHighlightedText(displayText, term)}
          </CategoryLink>
        ) : (
          item.name
        )}
      </li>
    );
  }
};

function parentsToString(parents) {
  if (!parents || parents.length === 0) return '';
  return (
    parents.map(parent => parent.object.breadcrumbText).join(' > ') + ' > '
  );
}

function isProduct(props) {
  return props.item.__typename === 'Product';
}

function getHighlightedText(text, highlight) {
  // Split on higlight term and include term into parts, ignore case
  const parts = text.split(
    new RegExp(
      `(${escapeRegExp(highlight)
        .split(' ')
        .join('|')})`,
      'gi'
    )
  );
  const highlightArray = highlight.split(' ');
  const toHighlightLower = highlightArray.map(word => word.toLowerCase());
  return parts.reduce((prev, curr, index) => {
    return [
      ...prev,
      toHighlightLower.includes(curr.toLowerCase()) ? (
        <strong
          style={{
            fontWeight: 600
          }}
          key={index}
        >
          {curr}
        </strong>
      ) : (
        curr
      )
    ];
  }, []);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export default SuggestedTerm;
