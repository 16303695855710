import t from '@jetshop/intl';
import { ErrorMessage, Field } from 'formik';
import { cx } from 'linaria';
import { styled } from 'linaria/react';

import React, { useState } from 'react';
import {
  InputError,
  InputPositive,
  ToggleViewPasswordButton
} from './InputStatus';

import { theme } from '../Theme';

export const Input = styled('input')`
  font-family: 'Roboto';
  height: 35px;
  background: #fcfcfc;
  border: 1px solid #333333;
  font-weight: 400;
  font-size: 13px;
  padding: 0.5em 1em;
  width: 100%;
  margin-bottom: 10px;
  padding-right: 40px;
  border-radius: 0;
  line-height: 35px;
  &.disabled {
    background: #ffffff;
    color: #808080;
  }
  &.error {
    color: red;
    border-color: red;
  }

  ${theme.below.lg} {
    font-size: 16px;
  }
`;

export const Wrapper = styled('div')`
  margin-bottom: 0rem;
  position: relative;
  span.input-counter {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 123%;
    position: absolute;
    top: 10px;
    right: 10px;
    &.error {
      color: red;
    }
  }
`;

export const ErrorSpan = styled('span')`
  font-style: italic;
  font-size: 11px;
  color: #eb0000;
  position: absolute;
  top: 30px;
  left: auto;
  right: 10px;
`;

export const Label = styled('label')`
  display: block;
  font-size: 12px;
  margin-bottom: 0.25rem;
  display: block;
  .req {
    color: #707070;
    margin-left: 0.5em;
  }
  &.disabled {
    color: #808080;
  }
`;

const InputWithLabel = ({
  label,
  disabled,
  error,
  success,
  warning,
  loading,
  name,
  required,
  type,
  disableValidation,
  showLabel = true,
  maxCount = 0,
  ...props
}) => {
  const [currentType, setType] = useState(type);
  const [currentLength, setSurrentLength] = useState(0);

  const handleLength = e => {
    setSurrentLength(e.target.value.length);
  };

  return (
    <Wrapper className={props.wrapperClassName}>
      {showLabel && (
        <Label className={cx(disabled && 'disabled')} htmlFor={name}>
          <span>{label || name}</span>
          {required && <span className="req">{t('(Required)')}</span>}
        </Label>
      )}
      <Field
        className={cx(disabled && 'disabled')}
        id={name}
        name={name}
        render={({ field, form: { touched, errors } }) => (
          <div>
            <Input
              {...props}
              {...field}
              id={name}
              disabled={disabled}
              type={currentType}
              onChange={event => {
                handleLength(event);
                field.onChange(event);
              }}
              className={maxCount > 0 && currentLength > maxCount && 'error'}
            />
            {maxCount > 0 && (
              <span
                className={`input-counter ${maxCount > 0 &&
                  currentLength > maxCount &&
                  'error'}`}
              >
                ({currentLength} / {maxCount})
              </span>
            )}
            {type === 'password' && (
              <ToggleViewPasswordButton
                className={currentType === 'password' ? 'hidden' : 'visible'}
                onClick={() => {
                  currentType === 'password'
                    ? setType('text')
                    : setType('password');
                }}
              />
            )}
            {!disableValidation && (
              <>
                {touched?.field?.name && errors?.field?.name && <InputError />}
                {touched?.field?.name && !errors?.field?.name && (
                  <InputPositive />
                )}
              </>
            )}
          </div>
        )}
      />
      {!disableValidation && <ErrorMessage name={name} component={ErrorSpan} />}
    </Wrapper>
  );
};

export default InputWithLabel;
