import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import Image from '@jetshop/ui/Image';
import { Link } from 'react-router-dom';
import MaxWidth from '../Layout/MaxWidth';
import 'slick-carousel/slick/slick.css';
import { SharedSlider, SliderContext } from '../ui/SharedSlider';
import { theme } from '../Theme';
import { createAnchorProps } from '../../utils/HelpFunctions';

const CampaignBannerRowWrapper = styled(MaxWidth)`
  padding: 1rem;
  background: ${props => props.bgcolor};
  display: flex;
  flex-direction: column;
  align-items: center;

  .campaign-header {
    max-width: 25%;
    text-align: center;
    margin-bottom: 1rem;
    ${theme.below.lg} {
      margin-top: 1rem;
      max-width: 80%;
    }

    h2 {
      font-weight: 700;
      font-size: 18px;
      margin: 0 0 5px 0;
      line-height: 1;
    }

    p {
      font-size: 10px;
    }
  }
`;

export const CampaignBannerRow = ({
  backgroundColor,
  title,
  text,
  anchorTag,
  children
}) => {
  const anchorProps = createAnchorProps(anchorTag?.value);
  return (
    <CampaignBannerRowWrapper bgcolor={backgroundColor?.value} {...anchorProps}>
      <div className={'campaign-header'}>
        {title?.value && <h2>{title.value}</h2>}
        {text?.value && <p>{text.value}</p>}
      </div>
      <SharedSlider desktopSlides={5} mobileSlides={2}>
        {children}
      </SharedSlider>
    </CampaignBannerRowWrapper>
  );
};

const CampaignBannerRowItemWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 0 6px;
`;

const childImageHoverStyle = css`
  img {
    transition: all, 0.5s ease !important;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`;
const ImageWrapper = styled('div')`
  overflow: hidden;
`;

const TextWrapper = styled('div')`
  padding: 10px 0;
  color: black;
  h3 {
    font-size: 14px;
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  p {
    font-size: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const CampaignBannerRowItem = ({ image, title, text, link }) => {
  const imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4];
  const sliderContext = useContext(SliderContext);

  return (
    <CampaignBannerRowItemWrapper
      className={childImageHoverStyle}
      onClick={e => {
        // prevent click when dragging in slider
        if (sliderContext?.dragging) {
          e.preventDefault();
        }
      }}
      to={link?.value}
    >
      <ImageWrapper>
        <Image src={image?.value} aspect={'25:37'} sizes={imageSizes} cover />
      </ImageWrapper>
      <TextWrapper>
        <h3>{title?.value}</h3>
        <p>{text?.value}</p>
      </TextWrapper>
    </CampaignBannerRowItemWrapper>
  );
};
