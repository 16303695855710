import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { withRouter, useLocation } from 'react-router';

const valid = [
  'fotball',
  'lop',
  'hockey',
  'klubbservice',
  'andre-idretter',
  'brann'
];

const displayNames = {
  lop: 'Løp',
  fotball: 'Fotball',
  klubbservice: 'Klubbservice',
  hockey: 'Hockey',
  'andre-idretter': 'Ander idretter',
  brann: 'brann'
};

const CookieHandler = ({ location }) => {
  const [, setCookie] = useCookies();

  useEffect(() => {
    const l = location.pathname.split('/')[1];
    if (l && valid.includes(l)) {
      setCookie('sportRoute', l, { path: '/' });
    } else {
      if (typeof window !== 'undefined') {
        setCookie('sportRoute', '', { path: '/' });
      }
    }
  }, [location.pathname, setCookie]);

  return null;
};

const useSportRoute = () => {
  const [cookies] = useCookies(['sportRoute']);
  const { pathname } = useLocation();
  const [, channel] = pathname.split('/');

  const browserSport = valid.find(c => c === channel);

  const currentSport = cookies['sportRoute'] ?? null;

  if (currentSport === 'search') {
    return {
      currentSport: null,
      startPath: '/',
      displayName: ''
    };
  }

  if (cookies['channel'] === 'Brann') {
    return {
      currentSport: 'brann',
      displayName: 'brann',
      startPath: '/brann',
      browserSport: 'brann'
    };
  }

  const startPath = `/${currentSport ?? ''}`;
  return {
    currentSport,
    startPath,
    displayName: displayNames[currentSport],
    browserSport
  };
};

const RouteCookieHandler = withRouter(CookieHandler);

export { RouteCookieHandler, useSportRoute };
