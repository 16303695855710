/* eslint-disable default-case */
import fetch from 'node-fetch';
import { useEffect, useReducer } from 'react';
import { useLocation } from 'react-router';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

export const fetchReducer = (state, action) => {
  switch (action.type) {
    case 'SUCCESS': {
      return {
        ...state,
        data: { ...action.data },
        success: true,
        loading: false,
        error: false
      };
    }
    case 'LOADING': {
      return {
        ...state,
        success: false,
        loading: true,
        error: false
      };
    }
    case 'ERROR': {
      return {
        ...state,
        data: { ...action.data },
        success: false,
        loading: false,
        error: true
      };
    }
    case 'RESET': {
      return {
        ...state,
        success: false,
        loading: false,
        error: false
      };
    }
  }
};

function useStartPageQuery() {
  const location = useLocation();
  const { startPageMapperIndex, apolloConfig } = useShopConfig();

  const { graphQLURI, token, shopid } = apolloConfig;

  const [state, dispatch] = useReducer(fetchReducer, {
    loading: false,
    error: false,
    startPage: null
  });

  useEffect(() => {
    async function getStartpage() {
      dispatch({ type: 'LOADING' });
      const response = await fetch(graphQLURI, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          channelid: startPageMapperIndex[location.pathname],
          shopid,
          token
        },
        body: JSON.stringify({
          query: startPageQuery()
        })
      });

      return response.json();
    }
    getStartpage()
      .then(({ data }) => dispatch({ type: 'SUCCESS', data: data.startPage }))
      .catch(() => dispatch({ type: 'ERROR' }));
  }, [graphQLURI, location.pathname, shopid, startPageMapperIndex, token]);

  if (!startPageMapperIndex[location.pathname]) {
    return { ...state };
  }

  return { ...state, startPage: state.data };
}

function startPageQuery() {
  return `
    query { startPage {
        head {
          title
          metaTags {
            name
            content
          }
        }
        primaryRoute {
          canonicalPath
          alternateRoutes {
            channelId
            culture
            route
            alias
          }
        }
        isActive
        breadcrumbText
        name
        content
        images {
          url
          width
          title
          height
        }
        items {
          row
          column
          id
          item {
            ... on Product {
              ...StartPageProductGrid
            }
            ... on CustomItem {
              name
              linkUrl
              content
              image {
                url
                title
                width
                height
              }
            }
          }
        }
      }
    }
    `;
}

export { useStartPageQuery };
