import React from 'react';
import { styled } from 'linaria/react';

const HtmlBoxWrapper = styled('div')`
  background: ${props => props.bgcolor};
  padding: ${props => props.pdding};
  p {
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
`;

export const HtmlBox = ({ html, backgroundColor, padding }) => {
  return (
    <HtmlBoxWrapper
      bgcolor={backgroundColor?.value ?? '#ffffff'}
      pdding={padding?.value}
      dangerouslySetInnerHTML={{
        __html: html?.value
      }}
    />
  );
};
