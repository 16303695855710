module.exports = {
  default: {
    colors: {
      white: '#FFFFFF',
      black: '#333333',
      main: '#333333',
      grey: '#878787',
      darkerGrey: '#767676',
      lightgrey: '#E8E8E8',
      tablegrey: '#F3F3F3',
      beige: '#f5f5dc',
      loadingBar: '#2f80ed',
      blue: '#0073BB',
      red: '#EB0000',
      background: '#f7f7f7',
      darkgrey: '#333',
      mediumgrey: '#9a9a9a',
      highlight: '#2f80ed',
      accent: '#0073BB',
      primary: '#0073BB',
      green: '#048A01',
      brann: '#de1215'
    },
    fontWeights: {
      light: 300,
      regular: 400,
      semibold: 700
    },
    breakpoints: {
      xs: '20rem',
      sm: '40rem',
      md: '50rem',
      lg: '64rem',
      xl: '80rem'
    },
    fontSizes: [
      '12px',
      '14px',
      '16px',
      '20px',
      '24px',
      '32px',
      '48px',
      '64px',
      '72px'
    ],
    space: [
      '0px',
      '8px',
      '16px',
      '24px',
      '32px',
      '40px',
      '48px',
      '56px',
      '64px'
    ],
    fonts: {
      primary: "'Roboto Condensed', sans-serif",
      secondary: "'Roboto', sans-serif"
    }
  },
  alt: {
    colors: {
      blue: 'blue',
      main: 'black'
    }
  }
};
