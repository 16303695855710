import React, { useContext } from 'react';
import CategoryMenuContainer, { MasterMenu } from './CategoryMenuContainer';
import { styled } from 'linaria/react';
import { useSportRoute } from '../../../RouteCookie';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Nav = styled('nav')`
  button {
    background: none;
    color: #666;
  }

  .menu-container {
    margin-top: 0;
  }
`;

export function CategoryMenu({ data }) {
  const { selectedChannel } = useContext(ChannelContext);

  const { currentSport } = useSportRoute();
  if (!currentSport && selectedChannel.id !== 7) {
    return (
      <Nav>
        <div className="menu-container">
          <MasterMenu />
        </div>
      </Nav>
    );
  }
  return (
    <Nav>
      <div key={'menuContainerPosed'} className="menu-container">
        {data?.categories?.length > 0 && (
          <CategoryMenuContainer queryData={data.categories[0].subcategories} />
        )}
      </div>
    </Nav>
  );
}
