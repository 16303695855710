import React, { useEffect } from 'react';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import clubUserQuery from './clubUserQuery.gql';
import { useQuery } from '@apollo/react-hooks';
import { useCookies } from 'react-cookie';

const ClubUserCookie = () => {
  const { loggedIn } = useAuth();
  const [cookies, setCookie] = useCookies(['userClub']);
  const { data, loading, error } = useQuery(clubUserQuery, {
    skip: !loggedIn
  });

  useEffect(() => {
    if (loggedIn && data && data.customer && data.customer.dynamicContent) {
      const clubData = JSON.parse(data.customer.dynamicContent);
      setCookie('userClub', clubData.club_code, { path: '/' });
    }
  }, [data, loggedIn]);
  return null;
};

export default ClubUserCookie;
