import { createSelectedChannel } from '@jetshop/core/ChannelHandler/channelUtils';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const RowWrapper = styled('div')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${props => (props.fullWidth ? '100%' : '1288px')};
  margin: ${props =>
    props.whitespace ? '0 auto 100px auto' : '0 auto 0 auto'};
  padding: ${props => (props.fullWidth ? '0' : '0 20px')};

  ${theme.below.lg} {
    display: block;
    flex: none;
    margin-bottom: 0;
    margin: ${props =>
      !props.whitespace && !props.textbelow
        ? '0 auto'
        : props.whitespace
        ? '50px auto'
        : '0 auto 10px auto'};

    .box.image-hidden {
      text-decoration: none;
      height: auto;
      .content {
        position: relative;
        width: 100%;
        padding: 30px;
      }
    }
    .box {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  .slick-arrow {
    display: none !important;
  }

  ${props => (props.fullWidth ? 'padding: 0;' : 'padding: 20px;')}
  ${props => (props.padding ? 'padding: 10px; .box {margin: 10px;}' : '')}
`;
const BoxWrapperLink = styled('a')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: ${props => props.width};
  align-items: ${props => props.vposition};
  justify-content: ${props => props.hposition};
  text-align: ${props => props.textalign};
  background: ${props => props.backgroundcolor};
  border: 2px solid white;
  color: ${props => (props.textbelow ? 'black' : props.color)};
  text-decoration: none;
  flex-direction: ${props => (props.textbelow ? 'column' : 'row')};
  background: #f5f5f5;
  padding: ${props => (props.textbelow ? '2px' : '0')} ${theme.below.lg} {
    margin: ${props =>
      !props.whitespace && !props.textbelow
        ? '0 auto'
        : props.whitespace
        ? '50px auto'
        : '0 auto 10px auto'};
  }

  &.no-link {
    cursor: default;
  }

  img {
    transition: all, 0.5s ease !important;
  }
  &:hover {
    &.image-link {
      img {
        transform: scale(1.05);
      }
    }
  }
  a {
    text-decoration: none;
    color: ${props => (props.textbelow ? 'black' : props.color)};
  }
  h2,
  h3,
  h4,
  p {
    color: ${props => (props.textbelow ? 'black' : props.color)};
  }
  div p {
    margin: ${props =>
      props.hposition === 'flex-start' && !props.textbelow
        ? '.5rem 0 .5rem 0'
        : '.5rem auto'};
  }
`;
const BoxWrapper = styled(Link)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: ${props => props.width};
  align-items: ${props => props.vposition};
  justify-content: ${props => props.hposition};
  text-align: ${props => props.textalign};
  background: ${props => props.backgroundcolor};
  border: 2px solid white;
  color: ${props => (props.textbelow ? 'black' : props.color)};
  text-decoration: none;
  flex-direction: ${props => (props.textbelow ? 'column' : 'row')};
  background: #f5f5f5;
  padding: ${props => (props.textbelow ? '2px' : '0')} ${theme.below.lg} {
    margin: ${props =>
      !props.whitespace && !props.textbelow
        ? '0 auto'
        : props.whitespace
        ? '50px auto'
        : '0 auto 10px auto'};
  }

  &.no-link {
    cursor: default;
  }

  img {
    transition: all, 0.5s ease !important;
  }
  &:hover {
    &.image-link {
      img {
        transform: scale(1.05);
      }
    }
  }
  a {
    text-decoration: none;
    color: ${props => (props.textbelow ? 'black' : props.color)};
  }
  h2,
  h3,
  h4,
  p {
    color: ${props => (props.textbelow ? 'black' : props.color)};
  }
  div p {
    margin: ${props =>
      props.hposition === 'flex-start' && !props.textbelow
        ? '.5rem 0 .5rem 0'
        : '.5rem auto'};
  }
`;
const Content = styled('div')`
  position: ${props => (props.textbelow ? 'relative' : 'absolute')};
  padding: ${props => (props.textbelow ? '30px' : '30px')};
  max-width: 100%;
  width: ${props => (props.textbelow ? '100%' : 'auto')};
  margin: auto;
  z-index: 1;
  color: ${props => (props.textbelow ? 'black' : 'black')};
  background: ${props => (props.textbelow ? '#F5F5F5' : 'transparent')};

  ${theme.below.lg} {
    padding: ${props => (props.textbelow ? '20px 20px 20px 20px' : '20px')};
  }

  h2 {
    font-size: ${props =>
      props.textbelow || props.fullwidth ? '25px' : '18px'};
    text-transform: ${props =>
      props.textbelow || props.fullwidth ? 'none' : 'uppercase'};
    letter-spacing: 0.5px;

    line-height: 1.2;
    ${theme.below.lg} {
      font-size: ${props => (props.textbelow ? '20px' : '16px')};
    }
  }
  h3 {
    font-size: 20px;
    letter-spacing: 0.5px;

    font-weight: 700;
    ${theme.below.lg} {
      font-size: 16px;
      &.large-not-active {
        font-size: 20px;
      }
    }
  }
  p {
    max-width: 80%;
    font-size: ${props => (props.textbelow ? '14px' : '1rem')};
    margin: ${props =>
      props.textbelow ? '.5rem auto' : '1.5rem auto 0.5rem auto'};
    ${theme.below.lg} {
      font-size: ${props => (props.textbelow ? '14px' : '12px')};
      max-width: 100%;
    }
  }
`;
const Price = styled('div')`
  font-size: 1rem;
  font-weight: bold;
  span.old {
    text-decoration: line-through;
    color: white;
  }
  span.new {
    background: ${theme.colors.red};
    color: white;
    padding: 3px;
    margin-left: 10px;
  }
`;

const ButtonsRow = styled('div')`
  &.multiple {
    margin: 10px -5px 0 -5px;
    > span {
      margin: 20px 5px 0 5px;
    }
  }
`;

export const FakeButton = styled('span')`
  display: inline-block;
  text-transform: uppercase;
  padding: 7px 30px;
  background: ${props => (props.background ? props.background : 'black')};
  font-size: 11px;
  font-weight: 700;
  margin-top: 20px;
  transition: all, 0.2s ease;
  color: ${props => (props.color ? props.color : 'white')};
  border: 1px solid ${props => props.bordercolor ?? props.background};

  &:hover {
    background: ${props =>
      props.backgroundhover ? props.backgroundhover : 'black'};
    color: ${props => (props.colorhover ? props.colorhover : 'white')};
  }
`;

export const LinkButton = styled(Link)`
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  padding: 7px 30px;
  background: ${props => (props.background ? props.background : 'black')};
  font-size: 11px;
  font-weight: 700;
  margin-top: 20px;
  transition: all, 0.2s ease;
  color: ${props => (props.color ? props.color : 'white')};
  border: 1px solid ${props => props.bordercolor ?? props.background};

  &:hover {
    background: ${props =>
      props.backgroundhover ? props.backgroundhover : 'black'};
    color: ${props => (props.colorhover ? props.colorhover : 'white')};
  }
`;

export const Dots = styled('ul')`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin: 0;
`;
export const Dot = styled('button')`
  background: #c4c4c4;
  border: 0;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin: 0 5px;
  &:focus {
    outline: none;
  }

  .slick-active & {
    background: ${theme.colors.primary};
  }
`;

export const Box = ({ box, fullwidth }) => {
  if (box.hposition === 'left') {
    box.hposition = 'flex-start';
    box.textAlign = 'left';
  }
  if (box.hposition === 'center') {
    box.hposition = 'center';
    box.textAlign = 'center';
  }
  if (box.hposition === 'right') {
    box.hposition = 'flex-end';
    box.textAlign = 'right';
  }

  if (box.vposition === 'top') {
    box.vposition = 'flex-start';
  }
  if (box.vposition === 'center' || box.vposition === 'middle') {
    box.vposition = 'center';
  }
  if (box.vposition === 'bottom') {
    box.vposition = 'flex-end';
  }

  const { updateChannel, channels } = useContext(ChannelContext);
  function handleClick(channelLink) {
    for (let i = 0; i < channels.length; i++) {
      const channel = channels[i];

      if (channelLink === channel.name) {
        updateChannel(createSelectedChannel(channel, null, null, 'NO'));
      }
    }
  }

  const imageSizes = [1, 1, 1, 1, 1];

  return (
    <>
      {box.external ? (
        <BoxWrapperLink
          href={box.link}
          target="_blank"
          onClick={() =>
            box.channelLink
              ? handleClick(box.channelLink)
              : !box.link
              ? e => e.preventDefault()
              : null
          }
          className={
            (box.image !== undefined
              ? 'box image-visible '
              : 'box image-hidden ') +
            (!box.link ? ' no-link ' : ' image-link ')
          }
          width={box.width ? box.width : 1}
          backgroundcolor={
            box.backgroundColor ? box.backgroundColor : 'transparent'
          }
          color={box.color ? box.color : 'white'}
          vposition={box.vposition ? box.vposition : 'center'}
          hposition={box.hposition ? box.hposition : 'center'}
          textalign={box.textAlign ? box.textAlign : 'center'}
          textbelow={box.textBelow ? 'true' : ''}
        >
          {box.image && box.image.length > 0 && (
            <Above breakpoint="lg">
              {matches =>
                matches ? (
                  <Image
                    sizes={'400'}
                    className="row-image"
                    aspect={box.aspect ? box.aspect : '1:1'}
                    cover
                    src={box.image}
                    quality={80}
                  />
                ) : (
                  <Image
                    sizes={'400'}
                    className="row-image"
                    aspect={box.mobileAspect ? box.mobileAspect : '1:1'}
                    cover
                    src={box.image}
                    quality={80}
                  />
                )
              }
            </Above>
          )}
          <Content
            className="content"
            color={box.color ? box.color : 'white'}
            textbelow={box.textBelow ? 'true' : ''}
            fullwidth={fullwidth}
          >
            {box.priceBefore && box.priceNow && (
              <Price>
                <span className="old">{box.priceBefore}</span>
                <span className="new">{box.priceNow}</span>
              </Price>
            )}
            {box.smallTitle && (
              <h3
                className={box.title ? 'large-active' : 'large-not-active'}
                dangerouslySetInnerHTML={{
                  __html: box.smallTitle
                }}
              />
            )}
            {box.title && (
              <h2
                className="box-title"
                dangerouslySetInnerHTML={{
                  __html: box.title
                }}
              />
            )}
            {box.text && (
              <p
                dangerouslySetInnerHTML={{
                  __html: box.text
                }}
              />
            )}
            {box.buttons && (
              <ButtonsRow className={box.buttons.length > 1 ? 'multiple' : ''}>
                {box.buttons.map((button, index) => (
                  <React.Fragment key={index}>
                    {!box.link && button.link ? (
                      <LinkButton
                        to={button.link}
                        key={index}
                        background={
                          button.background ? button.background : 'white'
                        }
                        color={button.color ? button.color : 'black'}
                        backgroundhover={
                          button.backgroundhover
                            ? button.backgroundhover
                            : 'black'
                        }
                        colorhover={
                          button.colorhover ? button.colorhover : 'white'
                        }
                      >
                        {button.text}
                      </LinkButton>
                    ) : (
                      <FakeButton
                        key={index}
                        background={
                          button.background ? button.background : 'white'
                        }
                        color={button.color ? button.color : 'black'}
                        backgroundhover={
                          button.backgroundhover
                            ? button.backgroundhover
                            : 'black'
                        }
                        colorhover={
                          button.colorhover ? button.colorhover : 'white'
                        }
                      >
                        {button.text}
                      </FakeButton>
                    )}
                  </React.Fragment>
                ))}
              </ButtonsRow>
            )}
          </Content>
        </BoxWrapperLink>
      ) : (
        <BoxWrapper
          to={{
            pathname: box.link
          }}
          target={box.external?.length > 0 ? '_blank' : '_self'}
          onClick={() =>
            box.channelLink
              ? handleClick(box.channelLink)
              : !box.link
              ? e => e.preventDefault()
              : null
          }
          className={
            (box.image !== undefined
              ? 'box image-visible '
              : 'box image-hidden ') +
            (!box.link ? ' no-link ' : ' image-link ')
          }
          width={box.width ? box.width : 1}
          backgroundcolor={
            box.backgroundColor ? box.backgroundColor : 'transparent'
          }
          color={box.color ? box.color : 'white'}
          vposition={box.vposition ? box.vposition : 'center'}
          hposition={box.hposition ? box.hposition : 'center'}
          textalign={box.textAlign ? box.textAlign : 'center'}
          textbelow={box.textBelow ? 'true' : ''}
        >
          {box.image && box.image.length > 0 && (
            <Above breakpoint="lg">
              {matches =>
                matches ? (
                  <Image
                    sizes={imageSizes}
                    className="row-image"
                    aspect={box.aspect ? box.aspect : '1:1'}
                    cover
                    src={box.image}
                    quality={80}
                    critical
                  />
                ) : (
                  <Image
                    sizes={'600'}
                    className="row-image"
                    aspect={box.mobileAspect ? box.mobileAspect : '1:1'}
                    cover
                    src={box.image}
                    quality={80}
                  />
                )
              }
            </Above>
          )}
          <Content
            className="content"
            color={box.color ? box.color : 'white'}
            textbelow={box.textBelow ? 'true' : ''}
            fullwidth={fullwidth}
          >
            {box.priceBefore && box.priceNow && (
              <Price>
                <span className="old">{box.priceBefore}</span>
                <span className="new">{box.priceNow}</span>
              </Price>
            )}
            {box.smallTitle && (
              <h3
                className={box.title ? 'large-active' : 'large-not-active'}
                dangerouslySetInnerHTML={{
                  __html: box.smallTitle
                }}
              />
            )}
            {box.title && (
              <h2
                className="box-title"
                dangerouslySetInnerHTML={{
                  __html: box.title
                }}
              />
            )}
            {box.text && (
              <p
                dangerouslySetInnerHTML={{
                  __html: box.text
                }}
              />
            )}
            {box.buttons && (
              <ButtonsRow className={box.buttons.length > 1 ? 'multiple' : ''}>
                {box.buttons.map((button, index) => (
                  <React.Fragment key={index}>
                    {!box.link && button.link ? (
                      <LinkButton
                        to={button.link}
                        key={index}
                        background={
                          button.background ? button.background : 'white'
                        }
                        color={button.color ? button.color : 'black'}
                        backgroundhover={
                          button.backgroundhover
                            ? button.backgroundhover
                            : 'black'
                        }
                        colorhover={
                          button.colorhover ? button.colorhover : 'white'
                        }
                      >
                        {button.text}
                      </LinkButton>
                    ) : (
                      <FakeButton
                        key={index}
                        background={
                          button.background ? button.background : 'white'
                        }
                        color={button.color ? button.color : 'black'}
                        backgroundhover={
                          button.backgroundhover
                            ? button.backgroundhover
                            : 'black'
                        }
                        colorhover={
                          button.colorhover ? button.colorhover : 'white'
                        }
                      >
                        {button.text}
                      </FakeButton>
                    )}
                  </React.Fragment>
                ))}
              </ButtonsRow>
            )}
          </Content>
        </BoxWrapper>
      )}
    </>
  );
};

const Row = ({
  padding,
  fullWidth,
  whiteSpace,
  mobileSlider,
  hideInMobile,
  hideInDesktop,
  boxes
}) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: dots => <Dots>{dots}</Dots>,
    customPaging: i => <Dot />
  };
  return (
    <Above breakpoint="lg">
      {matches =>
        matches ? (
          <>
            {!hideInDesktop && (
              <RowWrapper
                padding={padding ? true : false}
                fullWidth={fullWidth ? true : false}
                boxeslength={boxes.length}
                whitespace={whiteSpace ? 'true' : ''}
              >
                {boxes.map((box, index) => (
                  <React.Fragment key={index}>
                    {!box.hideInDesktop && (
                      <Box
                        key={index}
                        box={box}
                        fullwidth={fullWidth ? true : false}
                      />
                    )}
                  </React.Fragment>
                ))}
              </RowWrapper>
            )}
          </>
        ) : (
          <>
            {mobileSlider && !hideInMobile ? (
              <RowWrapper
                padding={padding ? true : false}
                fullWidth={fullWidth ? true : false}
                boxeslength={boxes.length}
                whitespace={whiteSpace ? 'true' : ''}
              >
                <Slider {...settings}>
                  {boxes.map((box, index) => (
                    <React.Fragment key={index}>
                      {!box.hideInMobile && (
                        <Box
                          key={index}
                          box={box}
                          fullwidth={fullWidth ? true : false}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Slider>
              </RowWrapper>
            ) : (
              <>
                {!hideInMobile && (
                  <RowWrapper
                    padding={padding ? true : false}
                    fullWidth={fullWidth ? true : false}
                    boxeslength={boxes.length}
                    whitespace={whiteSpace ? 'true' : ''}
                  >
                    {boxes.map((box, index) => (
                      <React.Fragment key={index}>
                        {!box.hideInMobile && (
                          <Box
                            key={index}
                            box={box}
                            fullwidth={fullWidth ? true : false}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </RowWrapper>
                )}
              </>
            )}
          </>
        )
      }
    </Above>
  );
};

export default Row;
