import React from 'react';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Hero } from '../Hero';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import { Link } from 'react-router-dom';

const HeroWrapper = styled('div')`
  width: 100%;
  max-width: 80rem;
  margin: auto;

  &.fullWidth {
    max-width: 100%;
  }
`;

export const boxStyling = css`
  h2 {
    font-weight: 700;
    font-size: 35px;
    margin: 0 0 5px 0;
    line-height: 1;
  }
  h3 {
    font-size: 16px;
    margin: 0 0 5px 0;
    line-height: 1;
    font-weight: 400;
  }
`;

const LinkedImageBoxLinkWrapper = styled(Link)`
  flex: ${props => props.flexwidth};
  text-decoration: none;

  [data-flight-image-children] {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
  }

  &.TOP {
    [data-flight-image-children] {
      justify-content: flex-start;
    }
  }

  &.MIDDLE {
    [data-flight-image-children] {
      justify-content: center;
    }
  }

  &.BOTTOM {
    [data-flight-image-children] {
      justify-content: flex-end;
    }
  }

  &.LEFT {
    [data-flight-image-children] {
      align-items: flex-start;
    }
  }

  &.CENTER {
    [data-flight-image-children] {
      align-items: center;
    }
  }

  &.RIGHT {
    [data-flight-image-children] {
      align-items: flex-end;
    }
  }
`;

const NoLinkWrapper = styled('div')`
  flex: ${props => props.flexwidth};
  text-decoration: none;

  [data-flight-image-children] {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
  }

  &.TOP {
    [data-flight-image-children] {
      justify-content: flex-start;
    }
  }

  &.MIDDLE {
    [data-flight-image-children] {
      justify-content: center;
    }
  }

  &.BOTTOM {
    [data-flight-image-children] {
      justify-content: flex-end;
    }
  }

  &.LEFT {
    [data-flight-image-children] {
      align-items: flex-start;
    }
  }

  &.CENTER {
    [data-flight-image-children] {
      align-items: center;
    }
  }

  &.RIGHT {
    [data-flight-image-children] {
      align-items: flex-end;
    }
  }
`;

export const MembersHero = ({ loggedIn, fullWidth, children, ...props }) => {
  const auth = useAuth();
  const isLoggedIn = auth?.loggedIn;

  //Generall (not logged in)
  if (!loggedIn?.value && !isLoggedIn) {
    return (
      <HeroWrapper className={fullWidth?.value && 'fullWidth'}>
        {props?.boxLink?.value && props?.boxLink?.value?.length > 0 ? (
          <LinkedImageBoxLinkWrapper
            to={props?.boxLink?.value}
            className={cx(
              boxStyling,
              props?.verticalAlign?.value,
              props?.horizontalAlign?.value
            )}
            flexwidth={props?.width?.value}
          >
            <Hero {...props} />
          </LinkedImageBoxLinkWrapper>
        ) : (
          <NoLinkWrapper
            className={cx(
              boxStyling,
              props?.verticalAlign?.value,
              props?.horizontalAlign?.value
            )}
            flexwidth={props?.width?.value}
          >
            <Hero {...props} />
          </NoLinkWrapper>
        )}
      </HeroWrapper>
    );
  }

  //Logged in
  if (loggedIn?.value && isLoggedIn) {
    return (
      <HeroWrapper className={fullWidth?.value && 'fullWidth'}>
        {props?.boxLink?.value && props?.boxLink?.value?.length > 0 ? (
          <LinkedImageBoxLinkWrapper
            to={props?.boxLink?.value}
            className={cx(
              boxStyling,
              props?.verticalAlign?.value,
              props?.horizontalAlign?.value
            )}
            flexwidth={props?.width?.value}
          >
            <Hero {...props} />
          </LinkedImageBoxLinkWrapper>
        ) : (
          <Hero {...props} />
        )}
      </HeroWrapper>
    );
  }

  return null;
};
