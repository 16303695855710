import { useLocation } from 'react-router';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';

const Wrapper = styled('div')`
  position: relative;
`;
const DropdownWrapper = styled('div')`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  z-index: 11;
  min-width: 180px;

  &.initial-open {
    position: relative;
    top: auto;
    right: auto;
    min-width: 0;
    width: 100%;
    ul {
      li {
        padding: 0;
        background: #f7f7f7;
        border-bottom: 1px solid #e6e6e6;
        a {
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-size: 14px;
          padding: 0.5rem 1rem;
        }
      }
    }
  }

  ul {
    display: block !important;
    border: 1px solid #f3f3f3;
    li {
      padding: 0 20px;
      border-bottom: 1px solid #f3f3f3;
      background: white;
      &:hover {
        background: #f7f7f7;
      }
      &:before {
        display: none !important;
      }
      a {
        text-decoration: none;
        display: block;
        text-align: left;
      }
    }
  }
`;

const StyledButton = styled('button')`
  background: #005c95;
  border: 0;
  color: white;
  text-align: center;
  padding: 0 15px;
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  height: 35px;
  line-height: 35px;
  letter-spacing: 0.5px;
  &.initial-open {
    width: 100%;
    display: block;
  }
  &:focus {
    outline: none;
  }
`;

export default function FakeChannelSelector({
  initialOpen,
  hideMenu,
  setShowChannels = () => {}
}) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  const links = [
    {
      text: 'Fotball',
      link: '/fotball'
    },
    {
      text: 'Løp',
      link: '/lop'
    },
    {
      text: 'Hockey',
      link: '/hockey'
    },
    {
      text: 'Klubbservice',
      link: '/klubbservice'
    },
    {
      text: 'Andre idretter',
      link: '/andre-idretter'
    }
  ];

  const linksNew = {
    start: 'Start',
    fotball: 'Fotball',
    lop: 'Løp',
    hockey: 'Hockey',
    klubbservice: 'Klubbservice',
    'andre-idretter': 'Andre idretter'
  };

  const handleClick = () => {
    if (setShowChannels != null && !open) {
      setShowChannels(true);
    } else {
      setShowChannels(false);
    }
  };

  return (
    <Wrapper>
      <StyledButton
        onClick={() => {
          setOpen(!open);
          handleClick();
        }}
        className={initialOpen && 'initial-open'}
      >
        <strong>{linksNew[location.pathname.split('/')[1]]}</strong> /{' '}
        <span>Bytt sport</span>
      </StyledButton>

      {open ? (
        <DropdownWrapper className={initialOpen && 'initial-open'}>
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                <Link
                  id={index === links.length - 1 ? 'last-channel' : ''}
                  to={link.link}
                  onClick={() => {
                    setOpen(!open);
                    hideMenu && hideMenu();
                  }}
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </DropdownWrapper>
      ) : null}
    </Wrapper>
  );
}
