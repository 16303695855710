import React from 'react';
import { LinkButton } from '../StartPage/Content/Row';
import { ReactComponent as Caret } from '../../svg/Caret.svg';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const ContentBoxLinkWrapper = styled('div')`
  display: flex;
  flex: ${props => props.flexwidth};
  flex-direction: column;
  justify-content: center;

  ${theme.below.lg} {
    padding: 2rem 1rem;
  }
  .inner {
    padding: 1rem;
  }

  &.LEFT {
    .inner {
      padding-right: 3rem;
    }
    align-items: flex-start;
    text-align: left;
  }

  &.CENTER {
    .inner {
      padding: 0 3rem;
    }
    align-items: center;
    text-align: center;
  }

  &.RIGHT {
    .inner {
      padding-left: 3rem;
    }
    align-items: flex-end;
    text-align: right;
  }
  .inner h2 {
    margin-bottom: 10px;
    font-size: 28px;
    font-weight: 700;
  }

  .content {
    p {
      font-size: 14px;
    }
  }

  a {
    width: fit-content;
    display: inline-block;
    .icon-wrapper {
      margin-left: 5px;
      display: inline-block;
      transform: rotate(270deg) !important;
    }
  }
`;

export const ContentBoxLink = ({
  width,
  title,
  content,
  link,
  linkText,
  contentAlign
}) => {
  const buttonProps = {
    background: 'black',
    color: 'white',
    backgroundhover: 'white',
    colorhover: 'black',
    bordercolor: 'white'
  };

  return (
    <ContentBoxLinkWrapper
      className={contentAlign?.value}
      flexwidth={width?.value ?? 1}
    >
      <div className={'inner'}>
        {title?.value && <h2>{title?.value}</h2>}
        {content?.value && (
          <div
            className={'content'}
            dangerouslySetInnerHTML={{
              __html: content?.value
            }}
          />
        )}
        {link?.value && linkText?.value && (
          <LinkButton to={link?.value} {...buttonProps}>
            {linkText?.value}
            <div className={'icon-wrapper'}>
              <Caret className={'link-svg'} />
            </div>
          </LinkButton>
        )}
      </div>
    </ContentBoxLinkWrapper>
  );
};
