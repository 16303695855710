import React from 'react';
import { styled } from 'linaria/react';
import { Query } from 'react-apollo';
import { theme } from '../../Theme';
import startPageProductsQuery from './StartPageProductsQuery.gql';
import ProductGrid from '../../CategoryPage/ProductGrid';

const Wrapper = styled('div')`
  width: 100%;
  max-width: 1288px;
  padding: 20px;
  margin: auto;
  ${theme.below.lg} {
    padding: 0;
  }

  &.product-page {
    h2 {
      font-size: 22px;
      ${theme.below.lg} {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
  }

  .product-card {
    width: 20%;
    flex: 1;
  }

  h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  ${theme.below.lg} {
    padding-bottom: 0;
    > ul {
      padding: 0 20px;
      padding-top: 1px;
      .product-card {
        min-width: 55vw;
        flex: none;
        a {
          margin-bottom: 0;
        }
      }

      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track,
      ::-webkit-scrollbar-thumb {
        background-color: transparent;
        display: none;
      }
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &.product-page {
    ul.product-grid {
      ${theme.above.lg} {
        justify-content: center;
      }
    }
    .product-card {
      ${theme.above.lg} {
        flex: none;
      }
    }
  }

  .variant-label {
    display: none !important;
  }
`;

const Products = ({ products, title, productPage, buyable }) => {
  return (
    <Query
      variables={products && { articleNumbers: products }}
      query={startPageProductsQuery}
      ssr={true}
      errorPolicy={'ignore'}
    >
      {({ loading, error, data }) => {
        if (loading || error) return null;
        if (data && !data.products.length) return null;
        return (
          <Wrapper
            className={productPage ? 'product-page' : 'not-product-page'}
          >
            {title ? <h2>{title}</h2> : null}
            <ProductGrid
              products={data.products.filter(
                product => product?.stockStatus?.buyable
              )}
              listName="Startpage products"
              loading={loading}
              imageSizes={[1 / 2, 1 / 2, 1 / 3, 1 / 4]}
              buyable={buyable}
            />
          </Wrapper>
        );
      }}
    </Query>
  );
};

export default Products;
