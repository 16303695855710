import { useLocation } from 'react-router';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import GoogleFont from '@jetshop/core/components/Fonts/GoogleFont';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { ProductListProvider } from '@jetshop/core/hooks/ProductList/ProductListContext';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';
import { Route, Switch, Redirect } from 'react-router-dom';
import loadFontCss from '../fonts/loadFontCss';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import MasterFooter from './Layout/Footer/MasterFooter';
import Header from './Layout/Header/Header';
import MasterHeader from './Layout/Header/MasterHeader';
import LoadingPage from './LoadingPage';
import { Favourites } from './ProductList/Favourites';
import { productListQueries } from './ProductList/productListQueries';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import { theme } from './Theme';
import '../globalStyles';
// import CookieConsent from './CookieConsent';
import { Notifications } from './Layout/Notifications';
import { RouteCookieHandler, useSportRoute } from './RouteCookie';
import { ClubActivatorChecker } from './MyPages/ClubActivator';
import ClubUserCookie from './MyPages/ClubUserCookie';

const Store = loadable(() => import('./Store/Store'), {
  fallback: <LoadingPage />
});

const StoreLocator = loadable(() => import('./StoreLocator/StoreLocator'), {
  fallback: <LoadingPage />
});

const NotFound = loadable(() => import('./NotFoundPage'), {
  fallback: <LoadingPage />
});

const LogInPage = loadable(() => import('./Auth/LogInPage'), {
  fallback: <LoadingPage />
});

const LogOutPage = loadable(
  () => import('@jetshop/core/components/AuthContext/LogOut'),
  {
    fallback: <LoadingPage />
  }
);

const PreviewRoute = loadable(
  () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  {
    fallback: <LoadingPage />
  }
);

const NavTreePage = loadable(() => import('./NavigationTree/NavTreePage'), {
  fallback: <LoadingPage />
});

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

export const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/CategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

export const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});
const LoadableSignUpPage = loadable(() => import('./Auth/Signup/SignUpPage'), {
  fallback: <LoadingPage />
});

const ForgotPassword = loadable(() => import('./Auth/ForgotPassword'), {
  fallback: <LoadingPage />
});

const ResetPassword = loadable(() => import('./Auth/ResetPassword'), {
  fallback: <LoadingPage />
});
const LoadableMyPages = loadable(() => import('./MyPages/MyPages'), {
  fallback: <LoadingPage />
});

function Shop() {
  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);
  const { startPath, displayName, currentSport } = useSportRoute();
  const location = useLocation();

  return (
    <GenericError>
      <ModalProvider>
        <Container>
          <LoadingBar color={theme.colors.loadingBar} />
          <CustomFont
            primaryFont={theme.fonts.primary}
            injectCss={loadFontCss}
          />
          <Helmet
            titleTemplate="%s - Torshov Sport"
            defaultTitle="Torshov Sport"
          />
          <GoogleFont
            primaryFont="Roboto+Condensed:300,400,700"
            secondaryFonts={['Roboto:300,400,700']}
          />
          <ProductListProvider queries={productListQueries}>
            {location.pathname === '/' && selectedChannel.id !== 7 ? (
              <MasterHeader />
            ) : (
              <Header />
            )}
            <Content>
              <ClubActivatorChecker />
              <ClubUserCookie />
              <PaginationProvider defaultProductsPerPage={40}>
                <Switch>
                  <Route exact path="/">
                    <LoadableStartPage />
                  </Route>
                  <Route exact path="/start">
                    <LoadableStartPage />
                  </Route>
                  <Route path="/favourites">
                    <Favourites />
                  </Route>
                  <Route path={routes.search.path}>
                    {displayName ? (
                      <Redirect
                        to={{
                          pathname: startPath + routes.search.path,
                          search:
                            location.search +
                            `&list[sokbar_i][0]=${currentSport}`
                        }}
                      />
                    ) : (
                      <LoadableSearchPage />
                    )}
                  </Route>

                  <Route path={startPath + routes.search.path}>
                    <LoadableSearchPage />
                  </Route>
                  <Route path={routes.signup.path}>
                    <LoadableSignUpPage />
                  </Route>
                  <Route path={routes.login.path}>
                    <LogInPage />
                  </Route>
                  <Route path={routes.logout.path}>
                    <LogOutPage />
                  </Route>
                  <Route path="/butikker">
                    <StoreLocator />
                  </Route>
                  <Route path={`/butikk/:id`}>
                    <Store />
                  </Route>
                  <Route path={routes.tree.path}>
                    <NavTreePage />
                  </Route>
                  <Route path={routes.myPages.path}>
                    <LoadableMyPages />
                  </Route>
                  <Route exact path={routes.forgotPassword.path}>
                    <ForgotPassword />
                  </Route>
                  <Route path={`${routes.resetPassword.path}/:token`}>
                    <ResetPassword />
                  </Route>
                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        productPage={LoadableProductPage}
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        categoryPage={LoadableCategoryPage}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={LoadableCategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
            </Content>
            {location.pathname === '/' && selectedChannel.id !== 7 ? (
              <MasterFooter />
            ) : (
              <Footer />
            )}
            <Notifications />
          </ProductListProvider>
          <ModalRoot />
          <ScrollRestorationHandler />
          <FaviconSelector />
          {/* <CookieConsent /> */}
          <RouteCookieHandler />
        </Container>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;

const FaviconSelector = () => {
  const { selectedChannel } = useContext(ChannelContext);

  return (
    <Helmet>
      {selectedChannel.id !== 7 ? (
        <>
          <link rel="shortcut icon" id="favicon" href="/favicon.png" />
        </>
      ) : (
        <>
          <link rel="shortcut icon" id="favicon" href="/favicon-brann.png" />
        </>
      )}
    </Helmet>
  );
};
