/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import ReactPlayer from 'react-player/youtube';
import {
  createAnchorProps,
  setVariableFromProp
} from '../../utils/HelpFunctions';
import { Above } from '@jetshop/ui/Breakpoints';

const VideoPositioner = styled('div')`
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 80rem;
  padding: 0 2rem;

  &.full-width {
    max-width: 100%;
    padding: 0;
  }

  &.LEFT {
    align-self: flex-start;
  }

  &.CENTER {
    align-self: center;
  }

  &.RIGHT {
    align-self: flex-end;
  }
`;
const YoutubeVideoWrapper = styled('div')`
  position: relative;
  padding-bottom: ${props => props.bottompadding}%;
  margin-bottom: 0px;
  width: 100% !important;
  height: auto !important;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

// Use Youtubes share url (e.g https://youtu.be/<id-here>)
export const DynamicYoutubeBox = ({
  videoUrl,
  desktopWidth = { value: '50%' },
  mobileWidth = { value: '100%' },
  position,
  autoplay,
  fullWidth,
  anchorTag
}) => {
  const [bottomPadding, setBottomPadding] = useState();
  const anchorProps = createAnchorProps(anchorTag?.value);
  const vUrl = setVariableFromProp(videoUrl);
  const videoID = vUrl?.split('https://youtu.be/')?.[1];

  useEffect(() => {
    if (!bottomPadding) {
      fetchVideoData();
    }
  }, []);

  // Fetches video data to set bottompadding for correct ratio
  const fetchVideoData = () => {
    return fetch(
      `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoID}&format=json`
    )
      .then(response => response.json())
      .then(data => setBottomPadding(videoRatioToPercent(data)))
      .catch(error => console.error(error));
  };

  const videoRatioToPercent = vData => {
    if (vData?.height && vData.width) {
      return (vData?.height / vData.width) * 100;
    }
    return null;
  };

  if (!videoID || !bottomPadding) {
    return null;
  }

  const videoProps = {
    height: 'auto',
    width: '100%',
    playing: !!autoplay?.value, // sets autoplay
    loop: !!autoplay?.value, // loops when autoplay
    controls: false,
    volume: !!autoplay?.value ? 0 : 50, //sets volyme of no autoplay
    muted: !!autoplay?.value //unmutes if no autoplay
  };

  return (
    <Above breakpoint="lg">
      {matches => (
        <VideoPositioner
          videowidth={matches ? desktopWidth?.value : mobileWidth?.value}
          className={cx(
            position?.value ?? 'CENTER',
            fullWidth?.value && 'full-width'
          )}
        >
          <YoutubeVideoWrapper
            {...anchorProps}
            className={'youtube'}
            bottompadding={bottomPadding}
          >
            <ReactPlayer url={`https://youtu.be/${videoID}`} {...videoProps} />
          </YoutubeVideoWrapper>
        </VideoPositioner>
      )}
    </Above>
  );
};
