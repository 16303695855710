import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';

const ImageGalleryPageControlsWrapper = styled('ul')`
  list-style: none;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  right: auto;
  display: flex;

  &.TOP {
    top: 0.5rem;
    bottom: auto;
  }
  &.MIDDLE {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
  &.BOTTOM {
    top: auto;
    bottom: 0.5rem;
  }
  &.LEFT {
    left: 0.5rem;
    right: auto;
  }
  &.CENTER {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  &.RIGHT {
    left: auto;
    right: 0.5rem;
  }

  button {
    div {
      border-radius: ${props => props.radius}px;
      background: ${props => props.backgroundcolor};
      box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
        rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
  }

  .slick-active {
    button {
      div {
        background: ${props => props.currentslideColor};
      }
    }
  }
`;

export const ImageGalleryPageControls = props => {
  const {
    indicators,
    className,
    backgroundColor,
    currentSlideColor,
    horizontalPosition,
    verticalPosition,
    radius
  } = props;
  return (
    <ImageGalleryPageControlsWrapper
      currentslideColor={currentSlideColor?.value}
      backgroundcolor={backgroundColor?.value}
      radius={radius?.value ?? '0'}
      className={cx(
        className,
        horizontalPosition?.value,
        verticalPosition?.value
      )}
    >
      {indicators}
    </ImageGalleryPageControlsWrapper>
  );
};

const IndicatorButton = styled('button')`
  height: 14px;
  width: 14px;
  background: transparent;
  padding: 2px;
`;

const Indicator = styled('div')`
  height: 100%;
  width: 100%;
`;

export const ImageGalleryPageControlIndicator = props => {
  return (
    <IndicatorButton onClick={props.onClick}>
      <Indicator />
    </IndicatorButton>
  );
};
