import React from 'react';
import { FakeButton, LinkButton } from '../StartPage/Content/Row';
import { Above } from '@jetshop/ui/Breakpoints';
import Image from '@jetshop/ui/Image';
import { cx, css } from 'linaria';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  color: ${props => props.textcolor};
  /* margin: 2rem; */

  ${theme.below.lg} {
    max-width: 100%;
  }

  &.LEFT {
    text-align: left;
    .buttons-row-wrapper {
      justify-content: flex-start;
    }
  }

  &.CENTER {
    text-align: center;
    .buttons-row-wrapper {
      justify-content: center;
    }
  }

  &.RIGHT {
    text-align: right;
    .buttons-row-wrapper {
      justify-content: flex-end;
    }
  }
`;

const BoxBadgeWrapper = styled('div')`
  position: absolute;
  padding: 0.5rem 1rem;
  background: ${props => props.bgcolor};
  color: ${props => props.textcolor};
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;

  &.TOP {
    top: 0;
    bottom: auto;
  }

  &.MIDDLE {
    top: 50%;
    bottom: auto;
  }

  &.BOTTOM {
    top: auto;
    bottom: 0;
  }

  &.LEFT {
    right: auto;
    left: 0;
    transform-origin: 0 0;
    transform: rotate(-90deg) translateX(-50%);
  }

  &.CENTER {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }

  &.RIGHT {
    right: 0;
    left: auto;
    transform-origin: 100% 0;
    transform: rotate(90deg) translateX(50%);
  }
`;

const ButtonsRowWrapper = styled('div')`
  display: flex;

  a + a,
  span + span {
    margin-left: 10px;
  }
`;

const BoxBadge = ({
  backgroundColor,
  textColor,
  text,
  horizontalAlign,
  verticalAlign
}) => {
  return (
    <BoxBadgeWrapper
      bgcolor={backgroundColor?.value ?? 'black'}
      textcolor={textColor?.value ?? 'white'}
      className={cx(
        horizontalAlign?.value ?? 'LEFT',
        verticalAlign?.value ?? 'BOTTOM'
      )}
    >
      {text?.value}
    </BoxBadgeWrapper>
  );
};

export const Hero = ({
  width,
  desktopImage,
  desktopImageRatio,
  mobileImage,
  mobileImageRatio,
  boxLink,
  verticalAlign,
  horizontalAlign,
  title,
  text,
  titleColor,
  button1Text,
  button1Url,
  button1Theme,
  button2Text,
  button2Url,
  button2Theme,
  badgeBackgroundColor,
  badgeTextColor,
  badgeText,
  badgeHorizontalAlign,
  badgeVerticalAlign,
  ...props
}) => {
  const button1Props = {
    background: button1Theme?.value === 'LIGHT' ? 'white' : 'black',
    color: button1Theme?.value === 'LIGHT' ? 'black' : 'white',
    backgroundhover: button1Theme?.value === 'LIGHT' ? 'black' : 'white',
    colorhover: button1Theme?.value === 'LIGHT' ? 'white' : 'black',
    bordercolor: button1Theme?.value === 'LIGHT' ? 'white' : 'white'
  };
  const button2Props = {
    background: button2Theme?.value === 'LIGHT' ? 'white' : 'black',
    color: button2Theme?.value === 'LIGHT' ? 'black' : 'white',
    backgroundhover: button2Theme?.value === 'LIGHT' ? 'black' : 'white',
    colorhover: button2Theme?.value === 'LIGHT' ? 'white' : 'black',
    bordercolor: button2Theme?.value === 'LIGHT' ? 'white' : 'white'
  };
  const imageSizes = [1, 1, 1, 300, 600];

  return (
    <div style={{ position: 'relative', width: '100%', position: 'relative' }}>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <Image
              src={desktopImage?.value}
              aspect={desktopImageRatio?.value}
              critical
              cover
            >
              {badgeText?.value && (
                <BoxBadge
                  backgroundColor={badgeBackgroundColor}
                  textColor={badgeTextColor}
                  text={badgeText}
                  horizontalAlign={badgeHorizontalAlign}
                  verticalAlign={badgeVerticalAlign}
                />
              )}
              <ContentWrapper
                className={cx(horizontalAlign?.value ?? 'CENTER')}
                textcolor={titleColor?.value ?? '#ffffff'}
              >
                {title?.value && <h2>{title?.value}</h2>}
                {text?.value && <h3>{text?.value}</h3>}
                <ButtonsRowWrapper className={'buttons-row-wrapper'}>
                  {boxLink?.value?.length > 0 ? (
                    <>
                      {button1Text?.value && (
                        <FakeButton {...button1Props}>
                          {button1Text?.value}
                        </FakeButton>
                      )}
                      {button2Text?.value && (
                        <FakeButton {...button2Props}>
                          {button2Text?.value}
                        </FakeButton>
                      )}
                    </>
                  ) : (
                    <>
                      {button1Text?.value && button1Url?.value && (
                        <LinkButton {...button1Props} to={button1Url?.value}>
                          {button1Text?.value}
                        </LinkButton>
                      )}
                      {button2Text?.value && button2Url?.value && (
                        <LinkButton {...button2Props} to={button2Url?.value}>
                          {button2Text?.value}
                        </LinkButton>
                      )}
                    </>
                  )}
                </ButtonsRowWrapper>
              </ContentWrapper>
            </Image>
          ) : (
            <Image
              src={mobileImage?.value}
              aspect={mobileImageRatio?.value}
              sizes={imageSizes}
              critical
              cover
            >
              {badgeText?.value && (
                <BoxBadge
                  backgroundColor={badgeBackgroundColor}
                  textColor={badgeTextColor}
                  text={badgeText}
                  horizontalAlign={badgeHorizontalAlign}
                  verticalAlign={badgeVerticalAlign}
                />
              )}
              <ContentWrapper
                className={cx(horizontalAlign?.value ?? 'CENTER')}
                textcolor={titleColor?.value ?? '#ffffff'}
              >
                {title?.value && <h2>{title?.value}</h2>}
                {text?.value && <h3>{text?.value}</h3>}
                <ButtonsRowWrapper className={'buttons-row-wrapper'}>
                  {boxLink?.value?.length > 0 ? (
                    <>
                      {button1Text?.value && (
                        <FakeButton {...button1Props}>
                          {button1Text?.value}
                        </FakeButton>
                      )}
                      {button2Text?.value && (
                        <FakeButton {...button2Props}>
                          {button2Text?.value}
                        </FakeButton>
                      )}
                    </>
                  ) : (
                    <>
                      {button1Text?.value && button1Url?.value && (
                        <LinkButton {...button1Props} to={button1Url?.value}>
                          {button1Text?.value}
                        </LinkButton>
                      )}
                      {button2Text?.value && button2Url?.value && (
                        <LinkButton {...button2Props} to={button2Url?.value}>
                          {button2Text?.value}
                        </LinkButton>
                      )}
                    </>
                  )}
                </ButtonsRowWrapper>
              </ContentWrapper>
            </Image>
          )
        }
      </Above>
    </div>
  );
};
