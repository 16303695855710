import React from 'react';
import { styled } from 'linaria/react';
import SearchField from './SearchField.js';
import AutocompleteQuery from './AutocompleteQuery.gql';
import { Intl } from '@jetshop/intl';

import { theme } from '../../../Theme';
import { Above } from '@jetshop/ui/Breakpoints';

// SearchAutoCompleteContainer does not allow any variablers.
import AutocompleteQueryFotball from './AutocompleteQuery_Fotball.gql';
import AutocompleteQueryHockey from './AutocompleteQuery_Hockey.gql';
import AutocompleteQueryLop from './AutocompleteQuery_Lop.gql';
import AutocompleteQueryAndreIdretter from './AutocompleteQuery_Andre-Idretter.gql';
import AutocompleteQueryKlubbservice from './AutocompleteQuery_Klubbservice.gql';
import AutocompleteQueryBrann from './AutocompleteQuery_Brann.gql';
import { useSportRoute } from '../../../RouteCookie';

const searchAutocompleteMapper = {
  '': AutocompleteQuery,
  fotball: AutocompleteQueryFotball,
  hockey: AutocompleteQueryHockey,
  lop: AutocompleteQueryLop,
  klubbservice: AutocompleteQueryKlubbservice,
  brann: AutocompleteQueryBrann,
  'andre-idretter': AutocompleteQueryAndreIdretter
};

const InnerSearchBar = styled('div')`
  margin: 0 auto;
  width: 100%;
  max-width: 600px;

  ${theme.below.lg} {
    padding: 0 15px;
  }

  svg {
    position: absolute;
    right: 20px;
    left: auto;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 20px !important;
    height: 20px !important;
    ${theme.below.lg} {
      right: 10px;
    }
  }
`;

const StyledSearchField = styled(SearchField)`
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0;
  ${theme.below.lg} {
    max-width: 100%;
  }
`;

const SearchBar = () => {
  const { browserSport, currentSport } = useSportRoute();

  // SearchAutoCompleteContainer does not allow any variablers.
  const currentSearchQuery = Object.keys(searchAutocompleteMapper).includes(
    currentSport || browserSport
  )
    ? searchAutocompleteMapper[currentSport || browserSport]
    : AutocompleteQuery;

  return (
    <InnerSearchBar className="search-bar">
      <Intl>
        {t => (
          <>
            <Above breakpoint="lg">
              {matches =>
                matches ? (
                  <StyledSearchField
                    autocompleteQuery={currentSearchQuery}
                    onCancel={() => null}
                    placeholder={t('Finn raskt det du er ute etter')}
                  />
                ) : (
                  <StyledSearchField
                    autocompleteQuery={currentSearchQuery}
                    onCancel={() => null}
                    placeholder={t('Søk')}
                  />
                )
              }
            </Above>
          </>
        )}
      </Intl>
    </InnerSearchBar>
  );
};

export { SearchBar };
